const formatPath = (path: string) => path.replace(/^\/*/, '').replace(/\/*$/, '');

/*
    We end with a slash because of a django error.
    RuntimeError at /manage/logout
    You called this URL via POST, but the URL doesn't end in a slash and you have APPEND_SLASH set.
    Django can't redirect to the slash URL while maintaining POST data.
    Change your form to point to test.digifabster.com/manage/logout/ (note the trailing slash),
    or set APPEND_SLASH=False in your Django settings.
 */
export const buildUrl =
    (ending = '') =>
    (...path: string[]) =>
        path.map(formatPath).join('/') + ending;
