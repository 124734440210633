import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import { RootState, ObjectModel, ObjectModelStatus, ObjectModelThumbStatus } from '@types';
import { findParentModel } from '@utils';
// import { findParentModel, memoizeArrayProducingFn } from '@utils';
import { selectUploadJob } from '../upload-models';

export const selectIsLoadingModels = (state: RootState) => state.models.isLoading;
export const selectIsModelsVerified = (state: RootState) => state.models.modelsVerified;
export const selectIsPollingModelsActive = (state: RootState) => state.models.isPollingActive;

export const selectSelectedModels = (state: RootState) => state.models.selectedModels;
export const selectModelsDict = (state: RootState) => state.models.data;
export const selectModelsList = createSelector(selectModelsDict, data => {
    return Object.values(data);
});
export const selectTransformedModelsData = (state: RootState) => state.models.transformedModelsData;
export const selectIsTransformedModelPollingActive = (state: RootState) => state.models.isTransformedModelPollingActive;
export const selectModelsError = (state: RootState) => state.models.modelsError;

export const selectRelatedModelsDataById = (state: RootState, modelId: number) =>
    state.models.relatedModelsData[modelId];

export const selectParentModelsList = createSelector(selectSelectedModels, selectModelsDict, (ids, models) =>
    ids.map(id => models[id]).filter(model => Boolean(model)),
);

export const selectParentModelsLoaded = (state: RootState) => {
    const ids = selectSelectedModels(state);
    const models = selectModelsDict(state);
    const unloadedModelsIds = ids.filter(id => !models[id]);
    return !Boolean(unloadedModelsIds.length);
};

// export const selectParentModelsMemoizedList = createSelector(
//     selectSelectedModels,
//     selectModelsDict,
//     memoizeArrayProducingFn((ids: number[], models: Record<string, ObjectModel>) => {
//         return ids.map(id => models[id]).filter(model => Boolean(model));
//     }, isEqual),
// );

export const selectTransformedModelsList = createSelector(selectTransformedModelsData, data => {
    return sortBy(
        Object.values(data).filter(
            transformedData => !transformedData.deleted && transformedData.data.status !== ObjectModelStatus.Ready,
        ),
        ['ts'],
    ).reverse();
});

export const selectTransformingModelsIds = createSelector(selectTransformedModelsData, data => {
    return Object.values(data)
        .filter(
            transformedData =>
                transformedData.data.id &&
                !transformedData.deleted &&
                transformedData.data.status === ObjectModelStatus.Progress,
        )
        .map(transformedData => transformedData.data.id as number);
});

export const selectNotLoadedModelsIds = createSelector(
    selectSelectedModels,
    selectModelsList,
    (modelsIds, modelsList) => {
        if (!modelsList.length) {
            return modelsIds;
        }

        const loadedModelsIds = modelsList.map(i => i.id);
        const newAddedModels = difference(modelsIds, loadedModelsIds);
        const inProgressIds = modelsList
            .filter(model => {
                const thumbInProgress = model.thumb_status === ObjectModelThumbStatus.Progress;
                // a hack to recognize that a model file is loaded on s3 otherwise,
                // the backend responds with a default django media url that we can't load due to CORS
                const notInAwsS3 = model.is_processable && !model.allowToRenderViewer;

                return thumbInProgress || notInAwsS3;
            })
            .map(i => i.id);

        inProgressIds.push(...newAddedModels);
        return inProgressIds;
    },
);

export const selectParentModelById = (state: RootState, modelId: number) => {
    const models = selectModelsDict(state);
    return findParentModel(models, modelId);
};

export const selectUploadedModelsList = createSelector(selectUploadJob, selectParentModelsList, (uploadJobId, models) =>
    uploadJobId ? models.filter(model => uploadJobId === model.upload_job_id) : [],
);
