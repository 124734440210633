import {
    CalculatePricePayload,
    CalculatePriceResult,
    CalculateBulkPricesPayload,
    CalculateBulkPriceResult,
    PagedResponse,
    PostProductionPricingResult,
    Product,
    Technology,
    WallThicknessPayload,
} from '@types';
import { axiosInstance } from '@services/axios-instance';

export class CompanyService {
    static init() {
        return new CompanyService();
    }

    loadTechnologies() {
        return axiosInstance.get<PagedResponse<Technology>>('/v2/technologies/', {
            params: {
                format: 'json',
            },
        });
    }

    loadUsersTechnologies() {
        return axiosInstance.get<PagedResponse<Technology>>('/v2/users/technologies/', {
            params: {
                format: 'json',
            },
        });
    }

    wallThicknessStatus(data: WallThicknessPayload) {
        return axiosInstance.post('/v2/wall_thickness/', {
            models_ids: [data.modelId],
            materials_ids: [data.materialId],
        });
    }

    calculatePrice(data: CalculatePricePayload) {
        return axiosInstance.post<CalculatePriceResult>('/v2/price/material/', data);
    }

    calculateBulkPrices(data: CalculateBulkPricesPayload) {
        return axiosInstance.post<CalculateBulkPriceResult>('/v2/batch_price/material/', data);
    }

    getPostProductionPricing(data: Pick<Product, 'material_id' | 'model_id'>) {
        return axiosInstance.post<PostProductionPricingResult>('/v2/price/post_production/', data);
    }

    uploadFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);

        return axiosInstance.put<{ uuid: string }>('/v2/upload_file/', formData);
    }
}
