export const NON_FIELD_ERROR_KEY = 'detail';
export const FallbackErrorsMessages: Record<string, string> = {
    'errors.required': 'Required',
    'errors.requiredForShippingRates': 'Required to receive shipping rates',
    'errors.chooseDeliveryType': 'Choose delivery type',
    'errors.invalidType': 'Invalid {{type}}',
    'errors.invalidEmail': 'Please enter a valid email address',
    'errors.minLength': 'At least {{min}} characters long',
    'errors.maxLength': 'No more than {{max}} characters long',
    'errors.emailDublicates': 'Email is already in the list',
};
