import get from 'lodash/get';
import intersection from 'lodash/intersection';
import { createSelector } from 'reselect';
import { ObjectModel, Product, RootState } from '@types';
import { findModelByProduct, findProductByModel } from '@utils';
import { selectModelsList, selectParentModelsList, selectSelectedModels } from '../models';
import { selectTechnologies } from '../technologies';
import { selectOrderData, selectIsOrderCreating } from '../order';
import { selectHasPricingData } from '../pricing';
import {
    selectFoundProduct,
    selectHasFoundProduct,
    selectProductIsAdding,
    selectProductIsFinding,
    selectProductIsUpdating,
} from '../product';

export const selectPreselectionModeOn = (state: RootState) => state.quotation.preselectionModeOn;
export const selectFullPreselectionModeOn = (state: RootState) => state.quotation.fullPreselectionModeOn;
export const selectRepeatSpecsModeOn = (state: RootState) => state.quotation.repeatSpecsModeOn;
export const selectCurrentProduct = (state: RootState) => state.quotation.currentProduct; // the product we are editing
export const selectWidgetCount = (state: RootState) => state.quotation.count;
export const selectWidgetModelId = (state: RootState) => state.quotation.currentModelId;
export const selectWidgetVisibleModelsIds = createSelector(
    selectSelectedModels,
    (state: RootState) => state.quotation.visibleModels,
    (ids, visibleIds) => intersection(ids, visibleIds),
);
export const selectWidgetVisibleModelsCount = (state: RootState) => state.quotation.visibleModels.length;
export const selectWidgetViewedModels = (state: RootState) => state.quotation.viewedModels;
export const selectWidgetSpecifications = (state: RootState) => state.quotation.specifications;
export const selectWidgetProductsSpecifications = (state: RootState) => state.quotation.productsSpecifications;

export const selectWidgetTechnologyId = (state: RootState) => {
    const widgetModelId = selectWidgetModelId(state);
    const currentProduct = selectCurrentProduct(state);
    const specifications = selectWidgetSpecifications(state);
    const productsSpecifications = selectWidgetProductsSpecifications(state);

    if (!widgetModelId) return;

    if (currentProduct) return get(productsSpecifications, [currentProduct.id, 'technologyId']);

    return get(specifications, [widgetModelId, 'technologyId']);
};

export const selectWidgetTechnology = (state: RootState) => {
    const technologyId = selectWidgetTechnologyId(state);
    const technologies = selectTechnologies(state);

    if (!technologyId) return;

    return technologies.find(technology => technology.id === technologyId);
};

export const selectWidgetMaterialId = (state: RootState) => {
    const widgetModelId = selectWidgetModelId(state);
    const currentProduct = selectCurrentProduct(state);
    const technologyId = selectWidgetTechnologyId(state);
    const specifications = selectWidgetSpecifications(state);
    const productsSpecifications = selectWidgetProductsSpecifications(state);

    if (!widgetModelId || !technologyId) return;

    if (currentProduct) return get(productsSpecifications, [currentProduct.id, 'materialIds', technologyId]);

    return get(specifications, [widgetModelId, 'materialIds', technologyId]);
};

export const selectWidgetMaterial = (state: RootState) => {
    const technology = selectWidgetTechnology(state);
    const materialId = selectWidgetMaterialId(state);

    if (!technology || !materialId) return;

    return technology.materials.find(material => material.id === materialId);
};

export const selectWidgetPriceConfig = (state: RootState) => {
    const widgetModelId = selectWidgetModelId(state);
    const currentProduct = selectCurrentProduct(state);
    const materialId = selectWidgetMaterialId(state);
    const specifications = selectWidgetSpecifications(state);
    const productsSpecifications = selectWidgetProductsSpecifications(state);

    if (!widgetModelId || !materialId) return;

    if (currentProduct) return get(productsSpecifications, [currentProduct.id, 'priceConfigs', materialId]);

    return get(specifications, [widgetModelId, 'priceConfigs', materialId]);
};

export const selectCorrectObjectModels = (state: RootState) => {
    const parentModelId = selectWidgetModelId(state);
    const specifications = selectWidgetSpecifications(state);

    if (!parentModelId) return;

    return get(specifications, [parentModelId, 'correctObjectModels']);
};

export const selectCorrectObjectModelId = (state: RootState) => {
    const technologyId = selectWidgetTechnologyId(state);
    const correctObjectModels = selectCorrectObjectModels(state);
    return technologyId && correctObjectModels ? correctObjectModels[technologyId] : undefined;
};

export const selectCorrectObjectModelIdByKeys = (
    state: RootState,
    { parentModelId, technologyId }: { parentModelId: number; technologyId: number },
) => {
    const specifications = selectWidgetSpecifications(state);
    if (!parentModelId || !technologyId) return;

    return get(specifications, [parentModelId, 'correctObjectModels', technologyId]);
};

export const selectWidgetModel = (state: RootState) => {
    const parentModelId = selectWidgetModelId(state);
    const correctObjectModelId = selectCorrectObjectModelId(state);
    const models = selectModelsList(state);
    const correctId = correctObjectModelId || parentModelId;
    return models.find(model => model.id === correctId);
};

export const makeSelectWidgetSpecificationByModelId = () =>
    createSelector(
        (state: RootState, modelId: number) => modelId,
        selectWidgetSpecifications,
        (modelId, specifications) => {
            if (!specifications[modelId]) return {};

            const technologyId = get(specifications, [modelId, 'technologyId']);
            if (!technologyId) return {};

            const materialId = get(specifications, [modelId, 'materialIds', technologyId]);

            return {
                correctObjectModel: get(specifications, [modelId, 'correctObjectModels', technologyId]),
                technologyId,
                materialId,
                priceConfig: materialId && get(specifications, [modelId, 'priceConfigs', materialId]),
            };
        },
    );

export const makeSelectWidgetProductSpecificationByProductId = () =>
    createSelector(
        (state: RootState, productId: string) => productId,
        selectWidgetProductsSpecifications,
        (productId, specifications) => {
            if (!specifications[productId]) return {};

            const technologyId = get(specifications, [productId, 'technologyId']);
            if (!technologyId) return {};

            const materialId = get(specifications, [productId, 'materialIds', technologyId]);

            return {
                technologyId,
                materialId,
                priceConfig: materialId && get(specifications, [productId, 'priceConfigs', materialId]),
            };
        },
    );

export const selectWidgetHasMaterial = (state: RootState) => Boolean(selectWidgetMaterialId(state));
export const selectHasCurrentProduct = (state: RootState) => Boolean(selectCurrentProduct(state));

export const selectFoundProductSameAsCurrent = (state: RootState) => {
    const currentProduct = selectCurrentProduct(state);
    const foundProduct = selectFoundProduct(state);
    return currentProduct?.id === foundProduct?.id;
};

/*
    We are editing a product (current), but its config is the same as another (found).
    To meet this condition, you need such a case:
        1) create two different products from one model (via duplicate)
        2) set the second product specification the same as the first one
 */
export const selectIsSameConfigAlreadyInCart = (state: RootState) => {
    const hasCurrentProduct = selectHasCurrentProduct(state);
    const hasFoundProduct = selectHasFoundProduct(state);
    const foundSameAsCurrent = selectFoundProductSameAsCurrent(state);
    return [
        // hasPricingData,
        hasCurrentProduct,
        hasFoundProduct,
        !foundSameAsCurrent,
    ].every(Boolean);
};

export const selectIsSameQuantityInCart = (state: RootState) => {
    const currentProduct = selectCurrentProduct(state);
    const hasFoundProduct = selectHasFoundProduct(state);
    const foundSameAsCurrent = selectFoundProductSameAsCurrent(state);
    const widgetCount = selectWidgetCount(state);
    return [
        // hasPricingData,
        !!currentProduct,
        hasFoundProduct,
        foundSameAsCurrent,
        currentProduct?.count === widgetCount,
    ].every(Boolean);
};

export const selectIsProductConfirmDisabled = (state: RootState) => {
    const hasMaterial = selectWidgetHasMaterial(state);
    const hasPricingData = selectHasPricingData(state);
    const isOrderCreating = selectIsOrderCreating(state);
    const isProductAdding = selectProductIsAdding(state);
    const isProductUpdating = selectProductIsUpdating(state);
    const isProductFinding = selectProductIsFinding(state);
    return [
        // hasErrors,
        !hasMaterial,
        !hasPricingData,
        isOrderCreating,
        isProductAdding,
        isProductUpdating,
        isProductFinding,
    ].some(Boolean);
};

export const selectModelByProduct = (state: RootState, product: Product) => {
    const models = selectParentModelsList(state);
    return findModelByProduct(models, product);
};

export const selectProductByModel = (state: RootState, model: ObjectModel) => {
    const order = selectOrderData(state);
    return order ? findProductByModel(order.products, model) : undefined;
};

export const selectWidgetNextModel = createSelector(
    selectParentModelsList,
    (state: RootState, modelId: number) => modelId,
    (models, modelId) => models.find(model => model.id !== modelId),
);

export const selectWidgetNextVisibleModel = createSelector(
    selectParentModelsList,
    selectWidgetVisibleModelsIds,
    (state: RootState, modelId?: number) => modelId,
    (models, visibleModels, modelId) => models.find(model => model.id !== modelId && visibleModels.includes(model.id)),
);
