import { Fragment, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { PostProductionOption, PriceConfigFormFields, PriceConfigFormPostProduction } from '@types';
import { Radio } from '@react-md/form';
import { CountInput } from '@components/count-input';
import { composeNodeId } from '@utils/text';
import { PostProductionRow } from './post-production-row';
import { addExclusivePostProduction, findPostProduction, renderLabel } from './helpers';
import { priceConfigId } from '../../helpers';

import styles from './post-production.module.scss';

interface CountableValues {
    [key: string]: number;
}

interface Props {
    exclusiveList: PostProductionOption[];
    fieldName: PriceConfigFormFields.PostProduction;
    priceConfigValue?: PriceConfigFormPostProduction[];
    groupTitle: string;
    data: PostProductionOption[];
    onCounterChange: (quantity: any, _: any, { dataset }: any) => void;
    onPostProductionOptionChange: (value: PriceConfigFormPostProduction) => void;
    onChange: (fieldName: PriceConfigFormFields.PostProduction, newValue: PriceConfigFormPostProduction[]) => void;
}

export function PostProductionExclusiveList({
    exclusiveList,
    fieldName,
    priceConfigValue = [],
    groupTitle: _groupTitle,
    data,
    onCounterChange,
    onPostProductionOptionChange,
    onChange,
}: Props) {
    const { exclusiveUuids, countableValues, currentChecked } = useMemo(() => {
        return exclusiveList.reduce<{
            exclusiveUuids: string[];
            countableValues: CountableValues;
            currentChecked: string | null;
        }>(
            (accum, { uuid, countable }) => {
                accum.exclusiveUuids.push(uuid);

                const currentValue = priceConfigValue.find(value => value.uuid === uuid);
                if (currentValue) {
                    accum.currentChecked = currentValue.uuid;
                }

                if (countable) {
                    accum.countableValues[uuid] = currentValue && currentValue.quantity ? currentValue.quantity : 0;
                }
                return accum;
            },
            { exclusiveUuids: [], countableValues: {}, currentChecked: null },
        );
    }, [exclusiveList, priceConfigValue]);
    const [checkedRadio, setCheckedRadio] = useState<string | null>(currentChecked);
    const [countableRadioState, setCountableRadioState] = useState<CountableValues>(countableValues);

    const handleClick = useCallback(
        (uuid: string) => {
            const newCheckedRadio = uuid === checkedRadio ? null : uuid;
            const quantity = newCheckedRadio ? countableRadioState[newCheckedRadio] : undefined;

            setCheckedRadio(newCheckedRadio);

            const newValue = addExclusivePostProduction({
                priceConfigValue,
                exclusiveUuids,
                data,
                uuid: newCheckedRadio,
                quantity,
            });
            onChange(fieldName, newValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    const handleCounterChange = useCallback(
        (quantity, valueAsString, { dataset }) => {
            onCounterChange(quantity, valueAsString, { dataset });
            setCountableRadioState(prev => {
                return { ...prev, [dataset.uuid]: quantity };
            });
        },
        [onCounterChange],
    );

    return (
        <>
            {exclusiveList.map(postProduction => {
                const { uuid, countable, colors } = postProduction;
                const radioId = priceConfigId(fieldName, 'radio', uuid);
                const counterId = priceConfigId(fieldName, 'counter', uuid);
                const currentValue = findPostProduction(priceConfigValue, uuid);
                const inputName = composeNodeId(fieldName, uuid);
                const label = renderLabel(postProduction);
                const groupTitle = _groupTitle || 'ungroupedRadioButtons';
                const isChecked = checkedRadio === uuid;
                const isDisabled = !isChecked;
                const RadioButton = (additionalClassName?: string) => (
                    <Radio
                        className={cn('rmd-toggle--radio', additionalClassName)}
                        id={radioId}
                        name={groupTitle}
                        label={label}
                        value={uuid}
                        checked={isChecked}
                        onClick={() => handleClick(uuid)}
                        //----- onChange needed to avoid an error "checked without onChange provided" -----//
                        onChange={() => {}}
                    />
                );

                return (
                    <PostProductionRow
                        key={uuid}
                        uuid={uuid}
                        countable={countable}
                        colors={colors}
                        fieldName={fieldName}
                        currentValue={currentValue}
                        onPostProductionOptionChange={onPostProductionOptionChange}
                    >
                        <Fragment>
                            {countable ? (
                                <>
                                    {RadioButton()}
                                    <div className={styles['counter-holder']}>
                                        <CountInput
                                            id={counterId}
                                            data-uuid={uuid}
                                            name={inputName}
                                            value={countableRadioState[uuid]}
                                            precision={0}
                                            min={0}
                                            max={1000}
                                            onChange={handleCounterChange}
                                            className={styles.counter}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </>
                            ) : (
                                RadioButton(styles['non-countable-input'])
                            )}
                        </Fragment>
                    </PostProductionRow>
                );
            })}
        </>
    );
}
