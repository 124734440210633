import { PaymentMethod } from '@services/df/codegen-enums';
import { Address, ContentCustomizationResponse, ObjectModelUnits, WidgetCustomizationValue, UserRoles } from '@types';
import { CustomAppConfigFields, LanguageList, SignInMode } from '@constants';

export enum MaterialSelectionMode {
    Preselection = 'preselection',
    RepeatSpecs = 'repeat_specs',
    FullPreselection = 'full_preselection',
}

export enum PaymentDocument {
    Invoices = 'invoices',
    Proforma = 'proforma',
}

export interface CurrencyConfigType {
    position: 'left' | 'right';
    symbol: string;
    title: string;
    separator: string;
    decimal: string;
    decimal_digits: number;
}

export interface AppSettingsData {
    // models
    allowed_extensions: string[];
    allowed_extensions_beauty: string[];
    model_size_limit_in_bytes: number;
    upload_models_limit: number;
    display_widget_units: ObjectModelUnits;

    // non cad
    allowed_non_processable_extensions: string[];
    non_processable_model_size_limit_in_bytes: number;
    is_drawings_optional_for_non_processable_file_formats: boolean;

    // company
    is_widget_blocked: boolean;
    does_company_have_machines: boolean;
    company_title: string;
    company_full_title?: string; // It's optional by now for testing to match old and new branch (DFDEV-1315)
    company_address: Address;
    terms_and_conditions: string;
    custom_locale?: string;
    support_email: string;
    currency: CurrencyConfigType;
    po_enabled: boolean;
    document_type: PaymentDocument;
    pro_forma_tax_included: boolean;
    pro_forma_title: string;
    offer_title: string;
    pre_offer_title: string;
    logo_url?: string;
    website?: string;
    widget_content_customization: ContentCustomizationResponse | null;
    widget_style_customization: WidgetCustomizationValue | null;
    custom_domain: string | null;
    material_selection_mode: MaterialSelectionMode;
    default_material_id: number | null;
    use_new_payment_page: boolean;
    company_has_promo_discounts: boolean;
    company_sign_in_mode: SignInMode; // mode shifts user login
    enabled_magic_links: boolean;
    current_payment_method: PaymentMethod;
    is_deactivated: boolean;
    is_blocked: boolean;

    // user
    is_user_blocked?: boolean;
    user_id?: number;
    roles: UserRoles;

    // order
    minimum_order_amount: number;
    use_local_pickup: boolean;
    use_delivery: boolean;
    force_company_order: boolean;
    vat_number_required: boolean;
    tax_name: string;
    tax_number_name?: string;
    require_address_on_pickup: boolean;
    customer_notes_placeholder?: string;
    additional_data_form_id?: string;
    tbd_label: string;

    // integrations
    stripe_public_key: string;
    stripe_account_id: string;

    // widget
    lang_codes?: LanguageList;
}

export type AppSettings = Omit<AppSettingsData, (typeof CustomAppConfigFields)[number]>;

// the order of the levels is important
export enum InvalidateLevel {
    Models,
    Order,
    Client,
    Full,
}

export type InvalidateStoreConfig = {
    redirect?: string;
    redirectByRouter?: boolean;
    purge: InvalidateLevel;
};
