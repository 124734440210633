import { AuthPopup, PopupOpenPayload, AppThunk, RootState } from '@types';
import { selectAppSettings } from '../app';
import { popupsActions } from '../ui';

type OpenAuthPopup = Partial<PopupOpenPayload>;

export const openAuthPopup =
    ({ type = AuthPopup.SignUp, ...rest }: OpenAuthPopup = {}): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const { enabled_magic_links } = selectAppSettings(state);

        type = enabled_magic_links ? AuthPopup.OTP : type;
        dispatch(popupsActions.open({ type, ...rest }));
    };

type CheckAuthAction = OpenAuthPopup & {
    checker: (state: RootState) => boolean;
    action: (state: RootState) => void;
};

// perform action if allowed, otherwise throw pop up
export const checkAuthAction =
    ({ checker, action, ...rest }: CheckAuthAction): AppThunk =>
    (dispatch, getState) => {
        const state = getState();

        if (checker(state)) {
            action(state);
        } else {
            dispatch(
                openAuthPopup({
                    onClose: state => {
                        const isActionAllowed = checker(state);
                        isActionAllowed && action(state);
                    },
                    ...rest,
                }),
            );
        }
    };
