import { useMemo } from 'react';
import partition from 'lodash/partition';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { PostProductionOption } from '@types';

interface PostProductionGroup {
    title: string;
    list: PostProductionOption[];
    exclusiveList: PostProductionOption[];
}

export function useGroupPostProduction(postProductionsList: PostProductionOption[]): PostProductionGroup[] {
    return useMemo(() => {
        const groups = map(groupBy(postProductionsList, 'group_title'), (values, key) => {
            const [exclusiveList, postProductionsList] = partition(values, value => value.is_exclusive);
            return {
                title: key,
                list: postProductionsList,
                exclusiveList,
            };
        });

        return sortBy(groups, group => {
            // an empty group is displayed first (post productions without group_title)
            if (!Boolean(group.title)) return -1;

            // compare pp indexes and sorting groups according to the pp order in the admin panel
            return postProductionsList.findIndex(postProduction => {
                const firstElementsUuid = group.list[0]
                    ? group.list[0].uuid
                    : group.exclusiveList[0]
                    ? group.exclusiveList[0].uuid
                    : '';
                return firstElementsUuid === postProduction.uuid;
            });
        });
    }, [postProductionsList]);
}
