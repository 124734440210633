import get from 'lodash/get';
import each from 'lodash/each';
import { UseFormSetError, FieldPath, FieldValues, FieldErrors } from 'react-hook-form';

export const API_ERROR_KEY = 'api-errors';

export function getFieldApiErrors<Values extends FieldValues>(
    errors: FieldErrors<Values>,
    fieldName: FieldPath<Values>,
) {
    const fieldError = get(errors, fieldName);
    return (fieldError?.type === API_ERROR_KEY && fieldError?.message ? fieldError.message : '') as string;
}

export function setApiErrors<Values extends FieldValues>(
    // setError: UseFormSetError<Values>,
    setError: (fieldName: keyof Values, error: { type: string; message: string }) => void,
    errors: Record<FieldPath<Values>, string | string[]>,
) {
    if (process.env.NODE_ENV !== 'production') {
        console.log(errors);
    }
    each(errors, (message, field) => {
        setError(field as keyof Values, {
            type: API_ERROR_KEY,
            message: Array.isArray(message) ? message[0] : message,
        });
    });
}

// export function setApiErrors<Values>(
//     // errors: { [P in keyof Values]?: string },
//
//     setError: (
//         fieldName: keyof Values,
//         error: { type: string; message: string }
//     ) => void,
//     errors: Record<Path<Values>, string>,
// ) {
//     Object.entries(errors).forEach(([field, message]) => {
//         setError(field as keyof Values, {
//             type: 'api-errors',
//             message,
//         });
//     })
// }
