import { isAxiosError } from 'axios';
import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { processRequestError, requestParamsSerializer } from '@utils';
// import { REHYDRATE } from 'redux-persist';
import { RootState } from '@types';
import { axiosInstance } from '../axios-instance';
import { extendQueryArgs, extendQueryParams } from './helpers';

export type FetchBaseQueryMeta = { response?: AxiosResponse };
export type FetchBaseQueryError = {
    status?: AxiosResponse['status'];
    data?: AxiosResponse['data'] | AxiosError['message'];
    message?: string;
    meta?: FetchBaseQueryMeta;
};
export type FetchBaseQueryArgs = {
    url: string;
    body?: AxiosRequestConfig['data'] | FormData;
} & AxiosRequestConfig;

// we use axios because of onUploadProgress https://stackoverflow.com/a/73186578

const baseQuery: BaseQueryFn<FetchBaseQueryArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> = async (
    { body, params, ...args },
    { signal, getState, endpoint },
    extraOptions,
) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
        (axiosInstance.defaults as any).headers['Authorization'] = `Token ${token}`;
    }

    params = extendQueryParams(endpoint, params);

    try {
        const result = await axiosInstance({
            data: body,
            params,
            signal,
            paramsSerializer: requestParamsSerializer,
            ...extendQueryArgs(endpoint, args),
        });
        return {
            data: result.data,
            meta: { response: result },
        };
    } catch (error) {
        return {
            error: processRequestError(error)!,
            meta: { response: isAxiosError(error) ? error.response : undefined },
        };
    }
};

export const dfApi = createApi({
    reducerPath: 'dfApi',
    baseQuery,
    endpoints: () => ({}),
    tagTypes: [
        // 'BackofficeAppSettings',
        'Client',
    ],
    // extractRehydrationInfo(action, { reducerPath }) {
    //     if (action.type === REHYDRATE) {
    //         return action.payload[reducerPath];
    //     }
    // },
});
