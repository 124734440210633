import { FetchBaseQueryError } from '@services/df/baseApi';
import { jsonToObject } from '@utils/text';

export function processEmailError(error: FetchBaseQueryError['data']) {
    const { detail } = error as FetchBaseQueryError['data'];
    const { objectFromJson: errorObject, isJson } = jsonToObject(detail);

    let resultErrorObject = error;

    if (isJson && 'message' in errorObject) {
        const { message } = errorObject;
        if (message.includes('email')) {
            resultErrorObject = { email: message };
        } else {
            resultErrorObject = { detail: message };
        }
    }

    return resultErrorObject;
}
