import { DropEvent, DropzoneOptions, FileError } from 'react-dropzone';
import { fileAccepted, fileMatchSize, TOO_MANY_FILES_REJECTION } from 'react-dropzone/src/utils';
import { getModelUploaderOptions } from '@pages/widget/upload/helpers';
import { selectAppSettings } from '@modules/app';
import { selectOrderId } from '@modules/order';
import { selectUserPermissions } from '@modules/user';
import { checkAuthAction } from '@modules/auth';
import { store } from '@app/store';
import { matchesOrderPage, buildOrderUrl } from '@utils';
import { router } from '@components/routes';

function checkTransferredFiles(files: File[] = [], dropzoneProps: DropzoneOptions) {
    const {
        accept,
        maxSize: _maxSize,
        minSize: _minSize,
        multiple,
        maxFiles: _maxFiles,
        validator,
        onDrop,
        onDropAccepted,
        onDropRejected,
    } = dropzoneProps;

    // mock event for compatibility
    const event = {} as DropEvent;

    // Values from Dropzone.defaultProps
    const maxFiles = _maxFiles ?? 0;
    const minSize = _minSize ?? 0;
    const maxSize = _maxSize ?? Infinity;

    const acceptedFiles: Parameters<NonNullable<DropzoneOptions['onDropAccepted']>>[0] = [];
    const fileRejections: Parameters<NonNullable<DropzoneOptions['onDropRejected']>>[0] = [];

    files.forEach(file => {
        const [accepted, acceptError] = fileAccepted(file, accept);
        const [sizeMatch, sizeError] = fileMatchSize(file, minSize, maxSize);
        const customErrors = validator ? validator(file) : null;

        if (accepted && sizeMatch && !customErrors) {
            acceptedFiles.push(file);
        } else {
            let errors = [acceptError, sizeError];

            if (customErrors) {
                errors = errors.concat(customErrors);
            }

            fileRejections.push({ file, errors: errors.filter(Boolean) as FileError[] });
        }
    });

    if ((!multiple && acceptedFiles.length > 1) || (multiple && maxFiles >= 1 && acceptedFiles.length > maxFiles)) {
        // Reject everything and empty accepted files
        acceptedFiles.forEach(file => {
            fileRejections.push({ file, errors: [TOO_MANY_FILES_REJECTION] });
        });
        acceptedFiles.splice(0);
    }

    if (onDrop) {
        onDrop(acceptedFiles, fileRejections, event);
    }

    if (fileRejections.length > 0 && onDropRejected) {
        onDropRejected(fileRejections, event);
    }

    if (acceptedFiles.length > 0 && onDropAccepted) {
        onDropAccepted(acceptedFiles, event);
    }
}

export function transferModels(files: File[]) {
    const { getState, dispatch } = store;
    const state = getState();

    const settings = selectAppSettings(state);
    const { isUploadingModelsAllowed } = selectUserPermissions(state);
    const orderId = selectOrderId(state);

    const orderUrl = buildOrderUrl({ orderId });
    const isOrderPage = matchesOrderPage(router.state.location.pathname);
    const initUpload = Boolean(isUploadingModelsAllowed && isOrderPage);

    const onDropComplete = () => {
        if (initUpload) {
            return;
        }

        const goToOrder = () => router.navigate(orderUrl, { replace: true });

        dispatch(
            checkAuthAction({
                action: goToOrder,
                checker: state => selectUserPermissions(state).isUploadingModelsAllowed,
            }),
        );
    };

    checkTransferredFiles(files, getModelUploaderOptions({ dispatch, settings, initUpload, onDropComplete }));
}
