import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import { RootState, Preselection } from '@types';
import { selectSelectedModels } from '../models';
import { selectWidgetModelId } from '../quotation';
import { PRESELECTION_POLL_CURRENT_ONLY } from './constants';

export const selectIsPollingPreselectionActive = (state: RootState) => state.preselection.isPollingActive;
export const selectPreselection = (state: RootState) => {
    return state.preselection.data;
};

export const selectPreselectionByModelId = (state: RootState, id: number) => {
    return state.preselection.data[id];
};

export const selectReadyPreselection = createSelector(selectPreselection, data =>
    Object.entries(data).reduce((acc, [modelId, value]) => {
        if (!value.is_ready) {
            return acc;
        }

        return { ...acc, [modelId]: value };
    }, {} as Record<string, Preselection>),
);

export const selectModelsIdsForPreselection = createSelector(
    selectWidgetModelId,
    selectSelectedModels,
    selectPreselection,
    (modelId, _modelsIds, data) => {
        const modelsIds = PRESELECTION_POLL_CURRENT_ONLY && modelId ? [modelId] : _modelsIds;
        const entries = Object.entries(data);

        if (!entries.length) {
            return modelsIds;
        }

        const preselectedModelsIds = Object.keys(data).map(id => parseInt(id));
        const newAddedModels = difference(modelsIds, preselectedModelsIds);

        return entries.reduce<number[]>((acc, [id, value]) => {
            if (!value.is_ready && (!PRESELECTION_POLL_CURRENT_ONLY || parseInt(id) === modelId)) {
                return [...acc, parseInt(id)];
            }

            return acc;
        }, newAddedModels);
    },
);

// export const selectPreselectTechnology = createSelector(
//     selectWidgetModelId,
//     selectTechnologiesItems,
//     selectReadyPreselection,
//     (widgetModelId, technologies, preselection) => {
//         if (!widgetModelId) {
//             return;
//         }
//
//         const currentPreselection = preselection[widgetModelId];
//         if (!currentPreselection) {
//             return;
//         }
//
//         return technologies.find(technology =>
//             technology.materials.find(material => {
//                 return material.id === currentPreselection.material;
//             }),
//         );
//     },
// );
//
// export const selectPreselectMaterial = createSelector(
//     selectPreselectTechnology,
//     selectWidgetModelId,
//     selectReadyPreselection,
//     (technology, parentModelId, preselection) => {
//         if (!technology || !parentModelId) {
//             return;
//         }
//
//         const currentPreselection = preselection[parentModelId];
//         return technology.materials.find(material => material.id === currentPreselection.material);
//     },
// );
