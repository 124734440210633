import { startAppListening } from '@app/listenerMiddleware';
import { popupsActions } from './popups';

startAppListening({
    predicate: action => popupsActions.open.match(action) && Boolean(action.payload.onClose),
    effect: async (action, { condition, dispatch, getState, unsubscribe, subscribe }) => {
        // only one process at a time
        unsubscribe();

        await condition(popupsActions.close.match);

        if (popupsActions.open.match(action)) {
            const state = getState();
            action.payload.onClose?.(state);
        }

        subscribe();
    },
});
