import zipObject from 'lodash/zipObject';
import { concat, from, of, timer, EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeWhile, withLatestFrom } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { combineEpics } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { AppEpic, UploadModelsResponseError } from '@types';
import { ModelsService } from '@services';
import { router } from '@components/routes';
import { AnalyticsCustomEvents, ROUTES, PollingConfig } from '@constants';
import { matchesOrderPage } from '@utils';
import { selectClientId } from '../client';
import { modelsActions, createModelsPolling } from '../models';
import { startPreselectionPolling } from '../preselection';
import { uploadModelsActions, createModelsStatusPolling } from './slice';
import { selectUploadJob, selectUploadModelsQueue, selectAnalyzingModelIds } from './selectors';

const createUploadJobEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModelsActions.createUploadJob.match),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            const clientId = selectClientId(state);
            const service = ModelsService.init();
            return from(clientId ? service.createUploadJobIqt(clientId) : service.createUploadJob()).pipe(
                map(({ data }) => uploadModelsActions.createUploadJobSuccess(data)),
                catchError(() => of(uploadModelsActions.createUploadJobFailure())),
            );
        }),
    );

const rebindeUploadJobEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModelsActions.rebindUploadJob.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(ModelsService.init().rebindUploadJob(action.payload)).pipe(
                map(({ data }) => uploadModelsActions.rebindUploadJobSuccess()),
                catchError((error: AxiosError) => of(uploadModelsActions.rebindUploadJobFailure())),
            );
        }),
    );

const onCreateUploadJobEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModelsActions.createUploadJobSuccess.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const upload = selectUploadModelsQueue(state);
            if (!upload.length) return EMPTY;

            return concat([
                uploadModelsActions.upload({
                    uj: action.payload.uj,
                    upload,
                }),
                // TODO update cart here with UJ
            ]);
        }),
    );

const initUploadingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModelsActions.initUploading.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            let upload = action.payload;

            const actions: Action[] = [];

            if (upload.length) {
                actions.push(uploadModelsActions.addAcceptedFiles(upload));
            } else {
                upload = selectUploadModelsQueue(state);

                if (!upload.length) return EMPTY;
            }

            const uj = selectUploadJob(state);

            if (uj) {
                actions.push(uploadModelsActions.upload({ uj, upload }));
                return concat(actions);
            }

            actions.push(uploadModelsActions.createUploadJob());
            return concat(actions);
        }),
    );

const uploadEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModelsActions.upload.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { uj, upload } = action.payload;

            return from(
                ModelsService.init().uploadModels({
                    uj,
                    upload,
                }),
            ).pipe(
                switchMap(({ data }) => {
                    data.forEach(({ object_models }) => {
                        object_models?.forEach(({ id, title }) => {
                            id &&
                                window.Analytics?.track(AnalyticsCustomEvents.ModelUploaded, {
                                    id,
                                    title,
                                });
                        });
                    });

                    return concat([
                        uploadModelsActions.uploadSuccess(
                            zipObject(
                                upload.map(file => file.uuid),
                                data.map(file => ({ uploaded: file })),
                            ),
                        ),
                        createModelsStatusPolling() as unknown as Action,
                    ]);
                }),
                catchError((error: AxiosError<UploadModelsResponseError>) => {
                    const { status, data } = error?.response || {};
                    return of(uploadModelsActions.uploadFailure({ status, detail: data?.detail }));
                }),
            );
        }),
    );

export const checkStatusEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModelsActions.checkStatus.match),
        switchMap(action => {
            return from(ModelsService.init().checkStatus(action.payload)).pipe(
                withLatestFrom(state$),
                switchMap(([{ data }, state]) => {
                    const actions: Array<Action> = [uploadModelsActions.checkStatusSuccess(data)];

                    // todo + create event readyCheckStatusReceived with payload as ids
                    // todo extract this logic and run on readyCheckStatusReceived for quotation pages (in listeners)
                    const ids = Object.entries(data)
                        .filter(([_, status]) => status === 'ready')
                        .map(([id, _]) => parseInt(id));

                    if (ids.length) {
                        actions.push(
                            ...[
                                modelsActions.addSelectedModels(ids),
                                createModelsPolling() as unknown as Action,
                                startPreselectionPolling() as unknown as Action,
                            ],
                        );
                    }

                    // quiet removing uploaded models with 'ready' status, if current page not 'company/widget/order'
                    // because on 'company/widget/order' page, logic of removing implemented on components level
                    // and rows removing is animated
                    const isOrderPage = matchesOrderPage(router.state.location.pathname);

                    if (!isOrderPage) {
                        actions.push(uploadModelsActions.deleteUploadedModelByIds(ids));
                    }

                    return concat(actions);
                }),
                catchError(() => of(uploadModelsActions.checkStatusFailure())),
            );
        }),
    );

const startStatusPollingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModelsActions.startModelsStatusPolling.match),
        switchMap(() =>
            concat(
                timer(PollingConfig.modelsStatus.delay, PollingConfig.modelsStatus.interval).pipe(
                    take(PollingConfig.modelsStatus.attempts),
                    withLatestFrom(state$),
                    map(([_, state]) => selectAnalyzingModelIds(state)),
                    takeWhile(modelsIds => !!modelsIds.length),
                    switchMap(modelsIds => concat([uploadModelsActions.checkStatus(modelsIds)])),
                ),
                of(uploadModelsActions.stopModelsStatusPolling()),
            ),
        ),
    );

export const uploadModelsEpics = combineEpics(
    startStatusPollingEpic,
    checkStatusEpic,
    uploadEpic,
    onCreateUploadJobEpic,
    initUploadingEpic,
    createUploadJobEpic,
    rebindeUploadJobEpic,
);
