import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPersistReducer, PersistReducerConfigs } from '@app/persist';
import {
    AppSettings,
    InitAppOptions,
    InvalidateStoreConfig,
    PaymentDocument,
    AppSettingsData,
    MaterialSelectionMode,
    ContentCustomizationResponse,
    ObjectModelUnitsTypes,
    WidgetCustomizationValue,
} from '@types';
import { PaymentMethod } from '@services/df/codegen-enums';
import { createAddress } from '../order/helpers/create-address';
import { AppSession } from '@utils';
import { CustomAppConfigFields, LANGUAGES_FALLBACK, SignInMode } from '@constants';

export interface AppReducerState {
    companyName: string;
    initialSuccess: boolean;
    settings: AppSettings;
    widget_style_customization: WidgetCustomizationValue;
    widget_content_customization: ContentCustomizationResponse;
    contentEditableMode: boolean;
    themeConfigIsOpened: boolean;
}

const initialState: AppReducerState = {
    companyName: AppSession.companyName,
    initialSuccess: false,
    settings: {
        // models
        allowed_extensions: [],
        allowed_extensions_beauty: [],
        model_size_limit_in_bytes: 1024 * 1024 * 50,
        upload_models_limit: 30,
        display_widget_units: ObjectModelUnitsTypes.Millimeters,

        // non cad
        allowed_non_processable_extensions: [],
        non_processable_model_size_limit_in_bytes: 1024 * 1024 * 50,
        is_drawings_optional_for_non_processable_file_formats: true,

        // company
        is_widget_blocked: false,
        does_company_have_machines: true,
        company_title: '',
        company_full_title: '',
        company_address: createAddress(),
        terms_and_conditions: '',
        support_email: 'hello@digifabster.com',
        currency: {
            decimal: '.',
            position: 'left',
            separator: ',',
            symbol: '$',
            title: 'usd',
            decimal_digits: 0,
        },
        custom_domain: null,
        material_selection_mode: MaterialSelectionMode.Preselection,
        default_material_id: null,
        use_new_payment_page: false,
        company_has_promo_discounts: false,
        company_sign_in_mode: SignInMode.Upload,
        enabled_magic_links: false,
        current_payment_method: PaymentMethod.WiresOnly,
        is_blocked: false,
        is_deactivated: false,

        // user
        roles: [],

        // order
        minimum_order_amount: 0,
        use_local_pickup: true,
        use_delivery: false,
        force_company_order: false,
        vat_number_required: false,
        tax_name: 'Sales Tax/VAT',
        require_address_on_pickup: false,

        // invoice
        po_enabled: false,
        document_type: PaymentDocument.Invoices,
        pro_forma_tax_included: false,
        pro_forma_title: 'Pro forma invoice/PO',
        offer_title: 'Firm offer',
        pre_offer_title: 'Budget offer',
        tbd_label: 'TBD',

        // integrations
        stripe_public_key:
            'pk_test_51Kj7bFIIpTIg11XJtvE76RnimbYycRpo2k8sXpjmKln27syw2XrJInmFJDC3QITWhbZYsQ8xtz5f24qHS1UTd7u600zZqhHYxF',
        stripe_account_id: '',

        // widget
        lang_codes: LANGUAGES_FALLBACK,
    },
    widget_style_customization: {},
    widget_content_customization: {},
    contentEditableMode: false,
    themeConfigIsOpened: false,
};

const filterCustomSettings = (data: Pick<AppSettingsData, (typeof CustomAppConfigFields)[number]>) =>
    pickBy(data, config => Boolean(config));

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        invalidateStore: (state, action: PayloadAction<InvalidateStoreConfig>) => {},
        initApp: (state, action: PayloadAction<InitAppOptions>) => {},
        initAppSuccess: (state, action: PayloadAction<AppSettingsData>) => {
            const customFields = filterCustomSettings(pick(action.payload, CustomAppConfigFields));

            return {
                ...state,
                ...customFields,
                settings: {
                    ...state.settings,
                    ...omit(action.payload, CustomAppConfigFields),
                },
                initialSuccess: true,
            };
            // state.initialSuccess = true;
        },
        toggleThemeConfigIsOpened: (state, action: PayloadAction<boolean | undefined>) => {
            state.themeConfigIsOpened = action.payload ?? !state.themeConfigIsOpened;
        },
        toggleContentEditableMode: state => {
            state.contentEditableMode = !state.contentEditableMode;
        },
        updateAppSettings: (
            state,
            action: PayloadAction<
                Partial<Pick<AppSettingsData, 'widget_content_customization' | 'widget_style_customization'>>
            >,
        ) => {},
        updateAppSettingsSuccess: (state, action: PayloadAction<AppSettingsData>) => {
            state.widget_content_customization = Object.assign(
                state.widget_content_customization,
                action.payload.widget_content_customization,
            );
            state.widget_style_customization = action.payload.widget_style_customization || {};
        },
    },
});

export const appReducer = createPersistReducer(PersistReducerConfigs.app, appSlice.reducer);
export const appActions = appSlice.actions;
