import cn from 'classnames';
import React, { useCallback } from 'react';
import { Typography } from '@react-md/typography';
import { Divider } from '@react-md/divider';
import { Checkbox } from '@react-md/form';
import { PriceConfigFormPostProduction as Value } from '@types';
import { composeNodeId } from '@utils';
import { CountInput } from '@components/count-input';
import { PostProductionProps } from '../types';
import {
    addPostProduction,
    addOrUpdatePostProduction,
    findPostProduction,
    // groupPostProduction,
    removePostProduction,
    renderLabel,
} from './helpers';
import { PostProductionExclusiveList } from './post-production-exclusive-list';
import { PostProductionRow } from './post-production-row';
import { priceConfigId } from '../../helpers';

import styles from './post-production.module.scss';
import { useGroupPostProduction } from './hooks';

const renderGroupLabel = ({ title, isFirst }: { title: string; isFirst: boolean }) => (
    <>
        {!isFirst && <Divider className="margin-top" />}
        <Typography type="headline-5" className={styles.group}>
            {title}
        </Typography>
    </>
);

export const PostProduction: React.FC<PostProductionProps> = ({ fieldName, data, priceConfigValue, onChange }) => {
    const handleCheckboxChange = useCallback(
        ({ target: { value: uuid, checked } }) => {
            const newValue = checked
                ? addPostProduction(data, priceConfigValue, { uuid })
                : removePostProduction(priceConfigValue, uuid);

            return onChange(fieldName, newValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    const handleCounterChange = useCallback(
        (quantity, _, { dataset }) => {
            const uuid = dataset.uuid;
            const newValue =
                quantity > 0
                    ? addOrUpdatePostProduction(data, priceConfigValue, { uuid, quantity })
                    : removePostProduction(priceConfigValue, uuid);

            return onChange(fieldName, newValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    const handlePostProductionOptionChange = (value: Value) =>
        onChange(fieldName, addOrUpdatePostProduction(data, priceConfigValue, value));

    const groups = useGroupPostProduction(data);

    return (
        <div className={cn('margin-top', styles.box)}>
            {groups.map(({ title, list, exclusiveList }, index) => {
                return (
                    <React.Fragment key={title}>
                        {title && renderGroupLabel({ title, isFirst: index === 0 })}

                        <div key={title} className={styles.grid}>
                            <PostProductionExclusiveList
                                exclusiveList={exclusiveList}
                                fieldName={fieldName}
                                priceConfigValue={priceConfigValue}
                                groupTitle={title}
                                data={data}
                                onCounterChange={handleCounterChange}
                                onPostProductionOptionChange={handlePostProductionOptionChange}
                                onChange={onChange}
                            />

                            {list.map(pp => {
                                const { uuid, countable, colors } = pp;
                                const nodeId = priceConfigId(fieldName, 'field', uuid);
                                const currentValue = findPostProduction(priceConfigValue, uuid);
                                const isSelected = Boolean(currentValue);

                                return (
                                    <PostProductionRow
                                        key={uuid}
                                        uuid={uuid}
                                        countable={countable}
                                        colors={colors}
                                        fieldName={fieldName}
                                        currentValue={currentValue}
                                        onPostProductionOptionChange={handlePostProductionOptionChange}
                                    >
                                        {countable ? (
                                            <>
                                                <Typography
                                                    type="body-1"
                                                    className={styles['counter-label']}
                                                    component={'label'}
                                                    {...{ htmlFor: nodeId }}
                                                >
                                                    {renderLabel(pp)}
                                                </Typography>

                                                <div className={styles['counter-holder']}>
                                                    <CountInput
                                                        id={nodeId}
                                                        data-uuid={uuid}
                                                        name={composeNodeId(fieldName, uuid)}
                                                        value={currentValue?.quantity || 0}
                                                        precision={0}
                                                        min={0}
                                                        max={1000}
                                                        onChange={handleCounterChange}
                                                        className={styles.counter}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <Checkbox
                                                className={styles['non-countable-input']}
                                                id={nodeId}
                                                name={composeNodeId(fieldName, uuid)}
                                                label={renderLabel(pp)}
                                                onChange={handleCheckboxChange}
                                                checked={isSelected}
                                                value={uuid}
                                                disableIconOverlay
                                                disableProgrammaticRipple
                                                disableRipple
                                            />
                                        )}
                                    </PostProductionRow>
                                );
                            })}
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};
