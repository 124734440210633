import cn from 'classnames';
import partial from 'lodash/partial';
import React, { ComponentProps, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonThemeProps } from '@react-md/button';
import { useToggle } from '@react-md/utils';
import { getCustomBackendUrl } from '@services/axios-instance';
import { LinkStyledButton } from '@components/link';
import { FeatureContent } from '@components/feature-content';
import { FeatureKeys as FF, FeatureOn, FeatureOff, FeatureToggle } from '@components/feature-flags';
import { PdfViewer } from '@components/pdf-viewer';
import { SidebarSection } from '@components/sidebar-section';
import { formatPdfDocumentUrl } from '@modules/order';
import { AppSettings, FCC, PaymentDocument } from '@types';
import { isValidUrl } from '@utils';
import { sidebarId } from '../helpers';

import styles from './invoice-sidebar-actions.module.scss';

type InvoicePdfLinkParamsKeys = 'budget_offer' | 'firm_offer' | 'proforma' | 'tax';
type InvoicePdfLinkParams = {
    [Key in InvoicePdfLinkParamsKeys]?: 0 | 1;
};

interface UseInvoicePdfLinkProps {
    settings: AppSettings;
    isWaitingForReview: boolean;
    isFirmOfferSent: boolean;
    pdfUrl: string;
}

const useInvoicePdfLink = ({ settings, isWaitingForReview, isFirmOfferSent, pdfUrl }: UseInvoicePdfLinkProps) => {
    const { custom_domain, document_type, pro_forma_tax_included, pro_forma_title, offer_title, pre_offer_title } =
        settings;

    const { t } = useTranslation();

    const _id = partial(sidebarId, 'generate_invoice' as const);
    let contentKey = _id();
    let label = t('links.generateInvoice', 'Generate Invoice');

    const params: InvoicePdfLinkParams = {};

    if (isWaitingForReview) {
        contentKey = _id('budget_offer');
        label = t('links.reviewDoc', 'Review {{ docType }}', { docType: pre_offer_title });
        params['budget_offer'] = 1;
    } else if (isFirmOfferSent) {
        contentKey = _id('firm_offer');
        label = t('links.reviewDoc', 'Review {{ docType }}', { docType: offer_title });
        params['firm_offer'] = 1;
    } else if (document_type === PaymentDocument.Proforma) {
        contentKey = _id('proforma');
        label = t('links.generateDoc', 'Generate {{ docType }}', { docType: pro_forma_title });
        params['proforma'] = 1;

        if (!pro_forma_tax_included) {
            params['tax'] = 0;
        }
    }

    const _link = isValidUrl(pdfUrl) ? pdfUrl : getCustomBackendUrl(pdfUrl, custom_domain);
    const link = formatPdfDocumentUrl(_link, params);

    const renderButton = (wrapper: ComponentProps<typeof FeatureContent>['wrapper']) => (
        <FeatureContent contentKey={contentKey} fallback={label} wrapper={wrapper} />
    );

    return {
        id: _id(),
        link,
        renderButton,
    };
};

export const InvoiceGeneratePdf = (props: UseInvoicePdfLinkProps) => {
    const { id: linkId, renderButton, link } = useInvoicePdfLink(props);

    const baseLinkProps: Partial<ButtonThemeProps> & HTMLAttributes<HTMLElement> = {
        id: linkId,
        theme: 'primary',
        themeType: 'outline',
        className: cn('rmd-button--text-large', 'w-full'),
    };

    const [isOpen, open, close] = useToggle(false);

    const { t } = useTranslation();

    return (
        <FeatureToggle flagKey={FF.InvoicePage.InvoicePdfPopupShown}>
            <FeatureOn>
                {renderButton(props => (
                    <Button {...props} {...baseLinkProps} onClick={open} />
                ))}

                <PdfViewer
                    id={sidebarId('invoice_pdf_dialog')}
                    title={t('titles.generatedInvoice', 'Generated PDF')}
                    closeButtonTitle={sidebarId('invoice_pdf_dialog')}
                    pdfLink={link}
                    isOpen={isOpen}
                    close={close}
                />
            </FeatureOn>
            <FeatureOff>
                {renderButton(props => (
                    <LinkStyledButton {...props} {...baseLinkProps} target="_blank" href={link} />
                ))}
            </FeatureOff>
        </FeatureToggle>
    );
};

export const InvoiceSidebarActions: FCC = ({ children }) => {
    return (
        <SidebarSection className={cn(styles.box)} span>
            <div className={cn(styles.grid)}>{children}</div>
        </SidebarSection>
    );
};
