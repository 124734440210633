import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import { catchError, filter, map, take, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of, from, concat, EMPTY } from 'rxjs';
import { combineEpics, StateObservable } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { AppEpic, RootState, InitialOrder, AddProductPayload } from '@types';
import { OrderService } from '@services';
import { findProductsByModel } from '@utils';
import { selectTechnologies } from '../technologies';
import { orderActions, selectOrderId, selectOrderData } from '../order';
import { modelsActions, selectParentModelsList } from '../models';
import {
    getProductsSpecifications,
    quotationActions,
    selectWidgetModelId,
    selectWidgetVisibleModelsIds,
    selectWidgetNextVisibleModel,
    selectCurrentProduct,
    selectModelByProduct,
} from '../quotation';
import { productActions, DraftedDrawings } from './slice';
import { selectRemovedProductsIds, selectDraftedDrawings } from './selectors';

const findProductEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(productActions.find.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const order = selectOrderData(state);
            return from(OrderService.init().findProduct(order!.id, action.payload)).pipe(
                map(({ data }) => productActions.findSuccess(data)),
                catchError(() => of(productActions.findFailure())),
            );
        }),
    );

const updateProductEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(productActions.update.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const order = selectOrderData(state);

            return from(
                OrderService.init().updateProduct(
                    order!.id,
                    action.payload.id,
                    pickBy(omit(action.payload, ['id', 'setAsFound']), Boolean),
                ),
            ).pipe(
                withLatestFrom(state$),
                switchMap(([{ data }, state]) => {
                    const currentProduct = selectCurrentProduct(state);
                    const currentWidgetModelId = selectWidgetModelId(state);
                    const product = data.order.products.find(p => p.id === data.purchase_id);

                    const actions: Action[] = [orderActions.setOrder(data.order)]; // todo slice

                    if (product) {
                        actions.push(productActions.updateSuccess(action.payload.setAsFound ? product : undefined)); // todo setAsFound pick from request meta

                        // Has the updated product been replaced?
                        if (currentProduct?.id === product.id) {
                            actions.push(quotationActions.setProduct(product)); // todo slice
                        }
                    }

                    return concat(actions);
                }),
                catchError(() => of(productActions.updateFailure())),
            );
        }),
    );

const removeProductEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(productActions.remove.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const order = selectOrderData(state);
            const removingProductId = action.payload.id;

            return from(OrderService.init().removeProduct(order!.id, removingProductId)).pipe(
                withLatestFrom(state$),
                switchMap(([{ data }, state]) => {
                    const actions: Action[] = [productActions.removeSuccess()];

                    const visibleModels = selectWidgetVisibleModelsIds(state);
                    const currentProduct = selectCurrentProduct(state);
                    const removedProductsIds = selectRemovedProductsIds(state);
                    const models = selectParentModelsList(state);

                    const product = action.payload;
                    const model = selectModelByProduct(state, product);

                    if (model) {
                        const modelId = model.id;
                        const isModelVisible = visibleModels.includes(modelId);
                        const isRemovingCurrent = removingProductId === currentProduct?.id;
                        const availableProducts = data.order.products.filter(
                            product => !removedProductsIds.includes(product.id),
                        );
                        const productsOfModel = findProductsByModel(availableProducts, model);
                        const isLastProductOfModel = !productsOfModel.length;
                        const isLastModel = models.length === 1;
                        const allowedModelRemoving = !isModelVisible && isLastProductOfModel;

                        // Test cases:
                        // 1) isRemovingCurrent isModelVisible !isLastProductOfModel => setProduct for same model
                        // 2) isRemovingCurrent !isModelVisible !isLastProductOfModel => setProduct for same model
                        // 3) isRemovingCurrent isModelVisible isLastProductOfModel => resetProduct
                        // 4) isRemovingCurrent !isModelVisible isLastProductOfModel => removeModel, if isLastModel => resetModel + resetProduct
                        //      else set another product + its own model if exists else another model if exists

                        // 5) !isRemovingCurrent isModelVisible !isLastProductOfModel => pass
                        // 6) !isRemovingCurrent isModelVisible isLastProductOfModel => pass
                        // 7) !isRemovingCurrent !isModelVisible !isLastProductOfModel => pass
                        // 8) !isRemovingCurrent !isModelVisible isLastProductOfModel => removeModel, if isLastModel => resetModel + resetProduct

                        if (allowedModelRemoving) {
                            if (isLastModel) {
                                actions.push(quotationActions.resetCurrentModel());
                            }

                            actions.push(modelsActions.removeSelectedModel(modelId));
                        }

                        if (isRemovingCurrent) {
                            if (!isLastProductOfModel) {
                                actions.push(quotationActions.setProduct(productsOfModel[0]));
                            } else if (isModelVisible && isLastProductOfModel) {
                                actions.push(quotationActions.resetCurrentProduct());
                            } else if (!isLastModel) {
                                const nextProduct = availableProducts.length ? availableProducts[0] : undefined;

                                if (nextProduct) {
                                    actions.push(quotationActions.setProduct(nextProduct));
                                } else {
                                    const nextModel = selectWidgetNextVisibleModel(state);

                                    if (nextModel) {
                                        actions.push(quotationActions.setModel({ modelId: nextModel.id }));
                                    } else {
                                        // we have in progress preselection
                                        actions.push(quotationActions.resetCurrentModel());
                                    }
                                }
                            }
                        }
                    }

                    actions.push(orderActions.setOrder(data.order)); // todo slice

                    return concat(actions);
                }),
                catchError(() => of(productActions.removeFailure())),
            );
        }),
    );

const uploadDraftedDrawingsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(productActions.addSuccess.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { product, draftedDrawings: _draftedDrawings } = action.payload;
            const draftedDrawings = selectDraftedDrawings(state);

            if (!_draftedDrawings) return EMPTY;

            const draftedDrawingsChanged =
                !draftedDrawings || draftedDrawings.parentModelId !== _draftedDrawings.parentModelId;

            // if the model was changed when adding the product,
            // upload drawings that were attached before pressing the "Confirm" button
            let drawings = _draftedDrawings.drawings;

            if (!draftedDrawingsChanged) {
                // difference, filter deleted drawings
                drawings = drawings.filter(file => draftedDrawings.drawings.includes(file));
            }

            return concat(drawings.map(file => productActions.addDrawing({ productId: product.id, file })));
        }),
    );

const addProductObservable$ = ({
    order: previousOrder,
    params,
    hideModel,
    draftedDrawings,
    state$,
}: {
    order: InitialOrder;
    params: AddProductPayload;
    hideModel: boolean;
    draftedDrawings?: DraftedDrawings;
    state$: StateObservable<RootState>;
}) => {
    return from(OrderService.init().addProduct(previousOrder.id, params)).pipe(
        withLatestFrom(state$),
        switchMap(([{ data }, state]) => {
            const nextCart = data.order;

            // analysing_errors case
            if (!nextCart) {
                const actions: Action[] = [productActions.addFailure()];
                const errors = data.analysing_errors;

                if (errors) {
                    actions.push(
                        modelsActions.updateObjectModelRelatedData({
                            model_id: params.model_id,
                            data: {
                                analysing_errors: { [params.material_id]: errors },
                            },
                        }),
                    );
                }

                return concat(actions);
            }

            const currentWidgetModelId = selectWidgetModelId(state);
            const currentProduct = selectCurrentProduct(state);
            const technologies = selectTechnologies(state);

            const actions: Action[] = [orderActions.setOrder(nextCart)]; // todo rtk query + set in slice extra reducer

            let product = nextCart.products.find(p => p.id === data.purchase_id);

            if (product) {
                actions.push(productActions.addSuccess({ product, draftedDrawings }));

                // this means that the same product was already in the cart
                // and the 'addProduct' request just increments the quantity of that product
                const isIncrementalAdding = previousOrder.products.find(p => p.id === data.purchase_id);

                if (!isIncrementalAdding) {
                    actions.push(
                        quotationActions.setProductsSpecifications(getProductsSpecifications([product], technologies)),
                    );
                }

                const sameModel = currentWidgetModelId === params.model_id; // model was not switched
                const needToSetProduct =
                    sameModel && (!currentProduct || (isIncrementalAdding && currentProduct.id === data.purchase_id));

                if (hideModel) {
                    actions.push(
                        quotationActions.hideModel({
                            modelId: params.model_id,
                            modelProduct: needToSetProduct ? product : undefined,
                        }),
                    );
                } else if (needToSetProduct) {
                    actions.push(quotationActions.setProduct(product));
                }
            }

            return concat(actions);
        }),
        catchError(error => of(productActions.addFailure())),
    );
};

const addProductEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(productActions.add.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const order = selectOrderData(state);
            const currentProduct = selectCurrentProduct(state);
            const draftedDrawings = selectDraftedDrawings(state);
            const addProductParams = action.payload;

            if (order) {
                return addProductObservable$({
                    order,
                    params: addProductParams,
                    hideModel: addProductParams.hideModel || !currentProduct, // "Confirm" was pressed when no current product existed for this model's config
                    draftedDrawings,
                    state$,
                });
            }

            return action$.pipe(
                filter(orderActions.createSuccess.match),
                switchMap(action =>
                    addProductObservable$({
                        order: action.payload,
                        params: addProductParams,
                        hideModel: true,
                        draftedDrawings,
                        state$,
                    }),
                ),
                take(4), // because addProductObservable always emits 4 actions on success after cart creation
            );
        }),
    );

const addDrawingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(productActions.addDrawing.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const orderId = selectOrderId(state)!;
            const { productId, file } = action.payload;

            return from(OrderService.init().addProductDrawing(orderId, productId, file)).pipe(
                map(({ data }) =>
                    productActions.addDrawingSuccess({
                        productId,
                        file: data,
                    }),
                ),
                catchError(() => of(productActions.addDrawingFailure())),
            );
        }),
    );

const removeDrawingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(productActions.removeDrawing.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const orderId = selectOrderId(state)!;
            const { productId, fileId } = action.payload;

            return from(OrderService.init().removeProductDrawing(orderId, productId, fileId)).pipe(
                map(({ data }) => productActions.removeDrawingSuccess()),
                catchError(() => of(productActions.removeDrawingFailure())),
            );
        }),
    );

export const productEpics = combineEpics(
    addProductEpic,
    updateProductEpic,
    removeProductEpic,
    findProductEpic,
    addDrawingEpic,
    removeDrawingEpic,
    uploadDraftedDrawingsEpic,
);
