// https://bobbyhadz.com/blog/javascript-convert-object-to-formdata

type BuildFormData = (form: FormData, data: any, parentKey?: string) => void;

const buildFormData: BuildFormData = (formData, obj, parentKey = '') => {
    if (obj == null) {
        return;
    } else if (Array.isArray(obj)) {
        obj.forEach(element => {
            buildFormData(formData, element, parentKey);
        });
    } else if (typeof obj === 'object' && !(obj instanceof File)) {
        Object.keys(obj).forEach(key => {
            buildFormData(formData, obj[key], parentKey ? `${parentKey}.${key}` : key);
        });
    } else {
        const value = typeof obj === 'number' || typeof obj === 'boolean' ? obj.toString() : obj;
        formData.append(parentKey, value);
    }
};

export const objectToFormData = (data: any) => {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
};
