import cn from 'classnames';
import isUndefined from 'lodash/isUndefined';
import { useTranslation } from 'react-i18next';
import { ColorPostProductionOption, FCC, PriceConfigFormFields, PriceConfigFormPostProduction } from '@types';
import { Typography } from 'react-md';
import { Collapse } from '@react-md/transition';
import { composeNodeId } from '@utils/text';
import { ColorFieldsContainer } from '@components/color-field';
import { Price } from '@components/price';
import { FeatureContent } from '@components/feature-content';
import { useAppSelector } from '@app/hooks';
import { selectPostProductionPricingData } from '@modules/post-production';
import { priceConfigId } from '../../helpers';

import styles from './post-production.module.scss';

interface Props {
    uuid: string;
    countable: boolean;
    colors?: ColorPostProductionOption[];
    fieldName: PriceConfigFormFields.PostProduction;
    currentValue?: PriceConfigFormPostProduction;
    onPostProductionOptionChange: (value: PriceConfigFormPostProduction) => void;
}

export const PostProductionRow: FCC<Props> = ({
    uuid,
    countable,
    colors,
    fieldName,
    currentValue,
    onPostProductionOptionChange,
    children,
}) => {
    const pricing = (useAppSelector(selectPostProductionPricingData) || {})[uuid];
    const { price, price_per_item, minimal_price, setup_cost, price_multiplier } = pricing || {};
    const hasMultiplier = price_multiplier > 0;
    const isSelected = Boolean(currentValue);
    const { t } = useTranslation();

    const renderPrice = () => {
        return isUndefined(pricing) ? null : <Price>{countable ? price_per_item : price}</Price>;
    };

    const minimalPrice = () =>
        minimal_price && minimal_price > price_per_item ? (
            <Typography type="subtitle-2" component={'div'} className={cn(styles.note, styles.subprice)}>
                <FeatureContent
                    contentKey={priceConfigId('post_production', 'minimal_price')}
                    fallback={t('labels.minPrice', 'Min. price')}
                />
                <Price precision={0}>{minimal_price}</Price>
            </Typography>
        ) : null;

    const setupCost = () =>
        setup_cost !== 0 ? (
            <Typography type="subtitle-2" component={'div'} className={cn(styles.note, styles.subprice)}>
                <FeatureContent
                    contentKey={priceConfigId('post_production', 'setup_cost')}
                    fallback={t('labels.setupCost', 'Excl. setup cost of')}
                />
                <Price precision={0}>{setup_cost}</Price>
            </Typography>
        ) : null;

    return (
        <>
            {children}
            <div className={cn(styles.pricing, 'w-full')}>
                {!hasMultiplier && (
                    <>
                        {renderPrice()}
                        {minimalPrice()}
                        {setupCost()}
                    </>
                )}
            </div>

            {/* post-production options, currently only colors may exist, if others appear, put it in a component */}
            {colors && (
                <Collapse
                    className={styles.collapse}
                    collapsed={!isSelected}
                    timeout={{
                        enter: 150,
                        exit: 150,
                    }}
                >
                    <ColorFieldsContainer
                        name={composeNodeId(fieldName, 'color', uuid)}
                        onChange={({ target: { value: colorUuid, checked } }) =>
                            onPostProductionOptionChange({
                                uuid,
                                option_uuid: colorUuid,
                            })
                        }
                        className={styles.color}
                        options={colors.map(({ uuid: colorUuid, label, color }) => ({
                            id: priceConfigId(fieldName, 'color_field', colorUuid),
                            value: colorUuid,
                            checked: currentValue?.option_uuid === colorUuid,
                            label,
                            color,
                        }))}
                    />
                </Collapse>
            )}
        </>
    );
};
