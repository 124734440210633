import cn from 'classnames';
import React, { createElement, SyntheticEvent } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Control, Controller, ControllerProps, FieldError } from 'react-hook-form';
import { TextFieldProps } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { FieldLabel } from '@components/label';
import { ValidationError } from '@types';
import { composeNodeId } from '@utils';
import { FallbackErrorsMessages } from './constants';

import styles from './form-field.module.scss';

export function getErrorMessage(t: TFunction, message?: FieldError['message'] | ValidationError) {
    if (message === undefined) {
        return null;
    } else if (typeof message === 'string') {
        return message;
    } else {
        const { key, values } = message;
        return t(key, FallbackErrorsMessages[key], values);
    }
}

export interface FormFieldProps<WidgetProps> {
    prefix: string;
    name: string;
    defaultValue?: ControllerProps['defaultValue'];
    component: React.ForwardRefExoticComponent<WidgetProps>;
    control?: Control<any>;
    className?: string;
    errorAsTooltip?: boolean;
    label?: string;
    hint?: React.ReactNode;
    widgetProps?: Partial<WidgetProps> & { type?: WidgetProps extends TextFieldProps ? string : never };
    // expansion of type is for testing for password type. If it's a password field we don't show the it in tip
}

export const FormField = <WidgetProps extends object>({
    prefix,
    name,
    defaultValue,
    component,
    control,
    className,
    errorAsTooltip,
    label,
    hint = null,
    children,
    widgetProps,
}: React.PropsWithChildren<FormFieldProps<WidgetProps>>) => {
    const { t } = useTranslation();
    const id = composeNodeId(prefix, name, 'field');
    const labelId = composeNodeId(prefix, name, 'label');

    return (
        <div className={cn('fieldset-grid-cell', styles.cell, className)}>
            {(label || hint) && (
                <div className={styles.head}>
                    {label && (
                        <FieldLabel id={labelId} htmlFor={id}>
                            <FeatureContent contentKey={labelId} fallback={label} />
                        </FieldLabel>
                    )}
                    {hint}
                </div>
            )}

            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({
                    field: { onChange: handleChange, onBlur: handleBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState: { isSubmitted },
                }) => {
                    const invalidField = (isTouched || isSubmitted) && (invalid || Boolean(error));

                    let title: string = value?.toString();
                    if (widgetProps?.type === 'password') {
                        title = '***';
                    }

                    const field = createElement<any>(
                        component,
                        {
                            ...widgetProps,
                            id,
                            name,
                            value,
                            title,
                            error: invalidField,
                            onChange: (e: SyntheticEvent) => {
                                handleChange(e);
                                // @ts-ignore
                                widgetProps?.onChange?.(e);
                            },
                            onBlur: (e: SyntheticEvent) => {
                                handleBlur();
                                // @ts-ignore
                                widgetProps?.onBlur?.(e);
                            },
                            ref,
                        },
                        children,
                    );

                    return (
                        <div className={styles.body}>
                            {field}

                            {invalidField && (
                                <Typography
                                    type="body-2"
                                    component="div"
                                    className={cn('fade-in', 'rmd-typography--theme-error', styles.error, {
                                        [styles.tooltip]: errorAsTooltip,
                                    })}
                                >
                                    {getErrorMessage(t, error?.message)}
                                </Typography>
                            )}
                        </div>
                    );
                }}
            />
        </div>
    );
};
