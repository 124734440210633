import { concat, from, timer, of, throwError } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    takeWhile,
    withLatestFrom,
} from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { shallowEqual } from 'react-redux';
import { Action } from '@reduxjs/toolkit';
import { AppEpic, RootState, InvalidateLevel, ObjectModelStatus } from '@types';
import { PollingConfig } from '@constants';
import { ModelsService } from '@services';
import { ObjectModelModel } from '@models';
import { reverse } from '@utils';
import { appActions } from '../app';
import { quotationActions, selectWidgetModelId } from '../quotation';
import { startPreselectionPolling } from '../preselection';
import { selectIqtModeOn } from '../user';
import { modelsActions } from './slice';
import { createModelsPolling, createTransformedModelsPolling } from './thunks';
import {
    selectSelectedModels,
    selectModelsDict,
    selectIsModelsVerified,
    selectTransformedModelsData,
    selectTransformingModelsIds,
    selectNotLoadedModelsIds,
} from './selectors';

const loadModelsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(modelsActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const iqtModeOn = selectIqtModeOn(state);
            const modelsIds = selectSelectedModels(state);

            return from(ModelsService.init().loadModels({ id: action.payload.join(','), limit: 100 }, iqtModeOn)).pipe(
                map(({ data }) => {
                    return data.results.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.id]: ObjectModelModel.from(item),
                        }),
                        {} as Record<string, ObjectModelModel>,
                    );
                }),
                withLatestFrom(state$),
                switchMap(([data, state]) => {
                    const modelsVerified = selectIsModelsVerified(state);
                    if (modelsVerified) {
                        return of(modelsActions.loadSuccess(data));
                    }

                    // check for deleted models, we don't have a UI for such cases, if deleted exist just invalidateStore
                    const isValid = modelsIds.length
                        ? modelsIds.every(id => Object.keys(data).map(Number).includes(id))
                        : true;

                    if (isValid) {
                        return of(modelsActions.setModelsVerified(true), modelsActions.loadSuccess(data));
                    }

                    return of(
                        appActions.invalidateStore({
                            purge: InvalidateLevel.Order,
                            redirect: reverse('widgetUpload'),
                            redirectByRouter: true,
                        }),
                    );
                }),
                catchError(() => of(modelsActions.loadFailure())),
            );
        }),
    );

const startModelsPollingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(modelsActions.startModelsPolling.match),
        switchMap(() =>
            concat(
                timer(PollingConfig.modelsAsyncFields.delay, PollingConfig.modelsAsyncFields.interval).pipe(
                    take(PollingConfig.modelsAsyncFields.attempts),
                    withLatestFrom(state$),
                    map(([_, state]) => selectNotLoadedModelsIds(state)),
                    takeWhile(modelsIds => !!modelsIds.length),
                    switchMap(modelsIds => concat([modelsActions.load(modelsIds)])),
                ),
                of(modelsActions.stopModelsPolling()),
            ),
        ),
    );

function getSuccessTransformActions(state: RootState, { readyModelIds }: { readyModelIds: number[] }) {
    const actions: Array<Action> = [];

    // if user has already loaded model, set it as current,
    // otherwise it will be set on preselection result or a loaded model
    const defineWidgetObjectsPayload: Parameters<typeof quotationActions.defineWidgetObjects>[0] = {};

    const widgetModelId = selectWidgetModelId(state);
    const models = selectModelsDict(state);

    if (!widgetModelId && Object.keys(models).includes(readyModelIds[0].toString())) {
        defineWidgetObjectsPayload.extendViewedModels = readyModelIds;
        defineWidgetObjectsPayload.modelId = readyModelIds[0];
    }

    actions.push(
        ...[
            modelsActions.addSelectedModels(readyModelIds),
            // todo quotationActions.checkForCurrent
            quotationActions.defineWidgetObjects(defineWidgetObjectsPayload),
            createModelsPolling() as unknown as Action,
            startPreselectionPolling() as unknown as Action,
        ],
    );

    return actions;
}

const scaleModelEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(modelsActions.scale.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const isIqtModeOn = selectIqtModeOn(state);
            return from(ModelsService.init().scale({ ...action.payload, isIqtModeOn })).pipe(
                withLatestFrom(state$),
                switchMap(([{ data }, state]) => {
                    const transformedModelResponseData = data[0].object_models![0];
                    const { status, detail } = transformedModelResponseData;

                    if (status === ObjectModelStatus.InitialFailed) {
                        return throwError(() => new Error(detail || 'Models upload limit is exceeded'));
                    }

                    const actions: Array<Action> = [
                        modelsActions.scaleSuccess({
                            ...action.payload,
                            data: transformedModelResponseData,
                        }),
                    ];

                    if (transformedModelResponseData.status === 'ready') {
                        const ids = [transformedModelResponseData.id!];

                        actions.push(...getSuccessTransformActions(state, { readyModelIds: ids }));
                    } else {
                        actions.push(createTransformedModelsPolling() as unknown as Action);
                    }

                    return concat(actions);
                }),
                catchError(error => of(modelsActions.scaleFailure(error))),
            );
        }),
    );

const rotateModelEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(modelsActions.rotate.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const isIqtModeOn = selectIqtModeOn(state);
            return from(ModelsService.init().rotate({ ...action.payload, isIqtModeOn })).pipe(
                withLatestFrom(state$),
                switchMap(([{ data }, state]) => {
                    const transformedModelResponseData = data[0].object_models![0];
                    const { status, detail } = transformedModelResponseData;

                    if (status === ObjectModelStatus.InitialFailed) {
                        return throwError(() => new Error(detail || 'Models upload limit is exceeded'));
                    }

                    const actions: Array<Action> = [
                        modelsActions.rotateSuccess({
                            ...action.payload,
                            data: transformedModelResponseData,
                        }),
                    ];

                    if (transformedModelResponseData.status === 'ready') {
                        const ids = [transformedModelResponseData.id!];

                        actions.push(...getSuccessTransformActions(state, { readyModelIds: ids }));
                    } else {
                        actions.push(createTransformedModelsPolling() as unknown as Action);
                    }

                    return concat(actions);
                }),
                catchError(error => of(modelsActions.rotateFailure(error))),
            );
        }),
    );

const startTransformedModelPollingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(modelsActions.startTransformedModelPolling.match),
        mergeMap(action =>
            concat(
                timer(PollingConfig.modelsStatus.delay, PollingConfig.modelsStatus.interval).pipe(
                    take(PollingConfig.modelsStatus.attempts),
                    withLatestFrom(state$),
                    map(([_, state]) => selectTransformingModelsIds(state)),
                    takeWhile(transformedModelsIds => !!transformedModelsIds.length),
                    switchMap(transformedModelsIds =>
                        from(ModelsService.init().checkStatus(transformedModelsIds)).pipe(
                            withLatestFrom(state$),
                            switchMap(([{ data }, state]) => {
                                const transformedModelsData = selectTransformedModelsData(state);

                                const actions: Array<Action> = [modelsActions.updateTransformedModelData(data)];

                                const responseReadyModelIds = Object.entries(data)
                                    .filter(([_, status]) => status === 'ready')
                                    .map(([id, _]) => parseInt(id));

                                if (responseReadyModelIds.length) {
                                    const readyModelIds = Object.values(transformedModelsData)
                                        .filter(
                                            ({ data, deleted }) =>
                                                !deleted && data.id && responseReadyModelIds.includes(data.id),
                                        )
                                        .map(({ data }) => data.id as number);

                                    if (readyModelIds.length) {
                                        actions.push(...getSuccessTransformActions(state, { readyModelIds }));
                                    }
                                }

                                return concat(actions);
                            }),
                            // catchError(() => of(uploadModelsActions.checkStatusFailure())),
                        ),
                    ),
                ),
                of(modelsActions.stopTransformedModelPolling()),
            ),
        ),
    );

const updateModelEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(modelsActions.update.match),
        distinctUntilChanged(
            (prev, next) =>
                prev.payload.modelId === next.payload.modelId && shallowEqual(prev.payload.data, next.payload.data),
        ),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const iqtModeOn = selectIqtModeOn(state);

            return from(ModelsService.init().updateModel(action.payload.modelId, action.payload.data, iqtModeOn)).pipe(
                switchMap(({ data }) => of(modelsActions.updateSuccess(ObjectModelModel.from(data)))),
                catchError(() => of(modelsActions.updateFailure())),
            );
        }),
    );

export const modelsEpics = combineEpics(
    loadModelsEpic,
    startModelsPollingEpic,
    updateModelEpic,
    scaleModelEpic,
    rotateModelEpic,
    startTransformedModelPollingEpic,
);
