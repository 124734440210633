import { Feature } from '@entities';
import { CncFeatureTypes } from './';

export interface TechnologyMaterialOption {
    note_for_user?: string;
    title: string;
    uuid: string;
}

export interface ColorsOption extends TechnologyMaterialOption {
    color: string;
}

export interface ColorPostProductionOption {
    color: string;
    label: string;
    uuid: string;
    note_for_user?: string;
}

export interface PostProductionOption extends TechnologyMaterialOption {
    group_title?: string;
    countable: boolean;
    colors?: ColorPostProductionOption[];
    is_exclusive: boolean;
}

export interface ExtraFieldsetOption {
    id: string;
    option: string;
}

export interface ExtraFieldset {
    id: string;
    is_active: boolean;
    sort_order: number;
    title: string;
    options: ExtraFieldsetOption[];
}

export type TechnologyMaterialOptions = TechnologyMaterialOption[] | ColorsOption[] | PostProductionOption[];

export enum DrawingsAccepting {
    NotAccepted = 'not_accept',
    Required = 'accept_require',
    NotRequired = 'accept_not_require',
}

export interface TechnologyMaterial {
    id: number;
    min_wall_thickness: number;
    note_for_user?: string;
    accept_drawing: DrawingsAccepting;
    printer_size: string; // length-width-height, e.g. 500-300-100 === x-y-z viewer scene axes
    sorting_priority: number;
    spec_sheet_url: string;
    title: string;
    filling: TechnologyMaterialOption[];
    is_iqt_only: boolean;
    layer_thickness: TechnologyMaterialOption[];
    lead_time: TechnologyMaterialOption[];
    thickness: TechnologyMaterialOption[];
    tolerance: TechnologyMaterialOption[];
    color: ColorsOption[];
    post_production: PostProductionOption[];
    extra_fieldsets: ExtraFieldset[];
    cnc_features_types: CncFeatureTypes;
    dfm_features: Feature[];
}

export interface Technology {
    id: number;
    tech_id: number;
    image: string;
    materials: TechnologyMaterial[];
    note: string;
    title: string;
}
