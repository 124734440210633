import { useEffect } from 'react';
import { TaskAbortError } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { addAppListener } from '@app/listenerMiddleware';
import { PollingConfig } from '@constants';
import { selectIqtModeOn } from '@modules/user';
import { selectWidgetModelId } from '../quotation';
import { preselectionActions, startPreselectionPolling } from './slice';
import { selectModelsIdsForPreselection } from './selectors';
import { PRESELECTION_POLL_CURRENT_ONLY } from './constants';

export const usePreselectionPolling = () => {
    const dispatch = useAppDispatch();
    const isIqtModeOn = useAppSelector(selectIqtModeOn);
    const parentModelId = useAppSelector(selectWidgetModelId);

    useEffect(() => {
        const unsubscribe = dispatch(
            addAppListener({
                actionCreator: preselectionActions.pollingStarted,
                effect: async (
                    action,
                    { condition, dispatch, extra: { ModelsService }, getState, fork, unsubscribe, subscribe },
                ) => {
                    // Only allow one instance of this listener to run at a time
                    unsubscribe();

                    const pollingTask = fork(async ({ delay, signal }) => {
                        try {
                            await delay(PollingConfig.preselection.delay);

                            while (true) {
                                const state = getState();
                                const modelsIds = selectModelsIdsForPreselection(state);

                                if (!modelsIds.length) {
                                    pollingTask.cancel();
                                }

                                try {
                                    const { data } = await ModelsService.init().loadPreselection({
                                        modelsIds,
                                        isIqtModeOn,
                                        config: {
                                            signal,
                                        },
                                    });

                                    dispatch(preselectionActions.loadSuccess(data));

                                    const readyPreselection = Object.entries(data).filter(
                                        ([_, preselection]) => preselection.is_ready,
                                    );

                                    if (readyPreselection.length) {
                                        dispatch(preselectionActions.readyReceived(readyPreselection));
                                    }
                                } catch (error) {
                                    dispatch(preselectionActions.loadFailure());
                                }

                                const latestState = getState();
                                if (!selectModelsIdsForPreselection(latestState).length) {
                                    pollingTask.cancel();
                                }

                                await delay(PollingConfig.preselection.interval);
                            }
                        } catch (err) {
                            if (err instanceof TaskAbortError) {
                                // could do something here to track that the task was cancelled
                            }
                            dispatch(preselectionActions.pollingStopped());
                        }
                    });

                    // Wait for the "stop polling" action
                    await condition(preselectionActions.pollingStopped.match, PollingConfig.preselection.length);
                    pollingTask.cancel();
                    subscribe();
                },
            }),
        );

        return () => {
            unsubscribe({ cancelActive: true });
        };
    }, [dispatch, isIqtModeOn]);

    // run on mount
    useEffect(() => {
        !PRESELECTION_POLL_CURRENT_ONLY && dispatch(startPreselectionPolling());
    }, [dispatch]);

    // run on model change
    useEffect(() => {
        PRESELECTION_POLL_CURRENT_ONLY && parentModelId && dispatch(startPreselectionPolling());
    }, [dispatch, parentModelId]);
};
