interface Props {
    arr: any[];
    property?: string;
    separateOnly?: boolean;
}

/**
 * @returns function for sorting
 * @param {any[]} [arr] sorting is performed according to the order of elements in the provided array. The returned function also moves items that are not included in the array to the end of the list.
 * @param {string} [property] if provided, the sorting function will compare the specified numeric property in objects.
 * @param {boolean} [separateOnly] if true, the sorting function will only move items not included in arr to the end of the list without sorting them.
 */

export const sortByArray =
    ({ arr, property, separateOnly = false }: Props) =>
    (prev: any, next: any) => {
        prev = property ? prev[property] : prev;
        next = property ? next[property] : next;

        const containsPrev = arr.includes(prev);
        const containsNext = arr.includes(next);

        if (!containsPrev && containsNext) {
            return 1;
        }

        if (containsPrev && !containsNext) {
            return -1;
        }

        return separateOnly ? 1 : arr.indexOf(prev) - arr.indexOf(next);
    };
