import { SignInMode } from '@constants';
import { UserRoleTypes, UserRoles, AppSettings } from '@types';
import { AppSession } from '@utils';

export const checkPermissionsByRoles = (userRoles: UserRoles, allowedRoles: UserRoles) =>
    userRoles.some(role => allowedRoles.includes(role));

export const defineUserPermissions = ({
    isAuthenticated,
    userRoles,
    settings,
}: {
    isAuthenticated: boolean;
    userRoles: UserRoles;
    settings: AppSettings;
}) => {
    const userIsCompanyOwner = checkPermissionsByRoles(userRoles, [UserRoleTypes.CompanyOwner]);
    const userIsVendor = checkPermissionsByRoles(userRoles, [UserRoleTypes.Vendor]);
    const userIsSalesman = checkPermissionsByRoles(userRoles, [UserRoleTypes.Salesman]);
    const userIsBlocked = checkPermissionsByRoles(userRoles, [UserRoleTypes.BlockedClient]);

    const roles = {
        userIsCompanyOwner,
        userIsVendor,
        userIsSalesman,
        userIsBlocked,
    };

    // actions with AuthRequired check (depending on settings)
    const authRequiredAccess = {
        isAskForHelpAllowed: isAuthenticated,
        isOrderPageAllowed: isAuthenticated,
        isConfirmProductAllowed: isAuthenticated,
        isUploadingModelsAllowed: isAuthenticated,
    };

    // const actions = {
    //     isConfirmProductAllowed: isAuthenticated,
    //     isOrderPageAllowed: isAuthenticated,
    //     isUploadingModelsAllowed: isAuthenticated,
    // };

    // combined variables
    const access = {
        iqtAllowed:
            isAuthenticated &&
            (userIsCompanyOwner || userIsSalesman) &&
            (!AppSession.iFrame || AppSession.shortIqtModeOn),
    };

    const permissions = {
        ...roles,
        ...authRequiredAccess,
        // ...actions,
        ...access,
    };

    // rewrite or extend permissions for anonymous, e.g. permissions.anyAction ||= calculated value;
    if (!isAuthenticated && settings) {
        switch (settings.company_sign_in_mode) {
            case SignInMode.AddToCart: {
                permissions.isOrderPageAllowed = true;
                permissions.isUploadingModelsAllowed = true;
                break;
            }
        }
    }

    return permissions;
};

export type Permissions = ReturnType<typeof defineUserPermissions>;
