import find from 'lodash/find';
import filter from 'lodash/filter';
import { PostProductionOption, PriceConfigFormPostProduction, PriceConfigFormPostProduction as Value } from '@types';
import { Typography } from '@react-md/typography';

import styles from './post-production.module.scss';

export function findPostProduction(selectedValues: Value[], uuid: string) {
    return find(selectedValues, { uuid });
}

export function removePostProduction(selectedValues: Value[], uuid: string) {
    return filter(selectedValues, i => i.uuid !== uuid);
}

export function addPostProduction(list: PostProductionOption[], selectedValues: Value[], newValue: Value): Value[] {
    if (!newValue.option_uuid) {
        const pp = find(list, { uuid: newValue.uuid });

        // set default color option
        if (pp?.colors) {
            newValue.option_uuid = pp.colors[0].uuid;
        }
    }

    return selectedValues?.length ? [...selectedValues, newValue] : [newValue];
}

interface AddExclusivePostProductionProps {
    priceConfigValue: PriceConfigFormPostProduction[];
    exclusiveUuids: string[];
    data: PostProductionOption[];
    uuid: string | null;
    quantity?: number;
}

export function addExclusivePostProduction({
    priceConfigValue,
    exclusiveUuids,
    data,
    uuid,
    quantity,
}: AddExclusivePostProductionProps) {
    const newValueWithoutExclusives = priceConfigValue.filter(postProduction => {
        return !exclusiveUuids.includes(postProduction.uuid);
    });

    const newValue =
        !uuid || quantity === 0
            ? newValueWithoutExclusives
            : quantity
            ? addOrUpdatePostProduction(data, newValueWithoutExclusives, { uuid, quantity })
            : addPostProduction(data, newValueWithoutExclusives, { uuid });
    return newValue;
}

export function addOrUpdatePostProduction(list: PostProductionOption[], selectedValues: Value[], newValue: Value) {
    const { uuid } = newValue;
    const prev = findPostProduction(selectedValues, uuid);

    if (prev) {
        // deduplicate previous choice
        return [...removePostProduction(selectedValues, uuid), { ...prev, ...newValue }];
    }

    return addPostProduction(list, selectedValues, newValue);
}

export const renderLabel = ({ title, note_for_user }: PostProductionOption) => (
    <>
        {title}
        {note_for_user && (
            <Typography type="subtitle-2" weight="semi-bold" className={styles.note} component={'span'}>
                {note_for_user}
            </Typography>
        )}
    </>
);
