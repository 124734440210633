import cn from 'classnames';
import React, { HTMLAttributes, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
import { ObjectModel, Technology } from '@types';
import { SidebarAskForHelp } from '@components/ask-questions';
import { Icons } from '@components/icons';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { FeatureKeys as FF, FeatureOn, FeatureToggle } from '@components/feature-flags';
import { PreloaderBox, PreloaderBoxProps } from '@components/preloader';
import { SidebarSection } from '@components/sidebar-section';
import {
    productActions,
    confirmProduct,
    selectIsApplyToAllModelsActive,
    selectProductIsAdding,
} from '@modules/product';
import { selectHasNotReadySuitableMaterial } from '@modules/suitable-materials';
import { selectIsOrderCreating, selectIsOrderLoading } from '@modules/order';
import {
    quotationActions,
    selectWidgetVisibleModelsIds,
    selectWidgetNextVisibleModel,
    selectWidgetHasMaterial,
    selectIsProductConfirmDisabled,
    selectIsSameConfigAlreadyInCart,
    selectIsSameQuantityInCart,
    selectHasCurrentProduct,
} from '@modules/quotation';
import { selectPricingErrors, selectIsPricingLoading } from '@modules/pricing';
import { AskForHelp as AFH } from '../ask-for-help';
import { CalculationConfig } from './calculation-config';
import { CalculationPricing } from './calculation-pricing';
import { CalculationHints } from './calculation-hints';
import { CalculationErrors } from './calculation-errors';
import { calculationSectionId } from '../helpers';
import { RootPrefix } from '../constants';

import styles from './calculation-section.module.scss';

function AskForHelp({ model }: { model: ObjectModel }) {
    return (
        <FeatureToggle flagKey={FF.CartPage.SidebarAskForHelpShown}>
            <FeatureOn>
                <SidebarAskForHelp
                    rootPrefix={RootPrefix}
                    align="left"
                    action={
                        <AFH modelId={model.parent_model || model.id} hasIcon={false} className="rmd-button--inline" />
                    }
                />
            </FeatureOn>
        </FeatureToggle>
    );
}

function CalculationActions({
    isAlreadyInCart,
    isConfirmDisabled,
}: {
    isAlreadyInCart: boolean;
    isConfirmDisabled: boolean;
}) {
    const hasCurrentProduct = useAppSelector(selectHasCurrentProduct);
    const isApplyToAllModelsNeeded = useAppSelector(state => Boolean(selectWidgetVisibleModelsIds(state).length));
    const isApplyToAllModelsActive = useAppSelector(selectIsApplyToAllModelsActive);
    const nextVisibleModel = useAppSelector(selectWidgetNextVisibleModel);

    const dispatch = useAppDispatch();
    const handleConfirmClick = useCallback(() => dispatch(confirmProduct()), [dispatch]);
    const handleApplyToAllModulesClick = useCallback(() => dispatch(productActions.runApplyToAllModels()), [dispatch]);
    const handleNextVisibleModelClick = useCallback(
        () => nextVisibleModel && dispatch(quotationActions.setModel({ modelId: nextVisibleModel.id })),
        [dispatch, nextVisibleModel],
    );

    const { t } = useTranslation();

    const applyToAll = isApplyToAllModelsNeeded && (
        <FeatureToggle flagKey={FF.CartPage.ApplyToAllModelsOn} key="apply_to_all_models">
            <FeatureOn>
                <FeatureContent
                    contentKey={calculationSectionId('apply_to_all_models')}
                    fallback={t('buttons.applyToAllModules', 'Apply to all models')}
                    wrapper={props => (
                        <Button
                            theme="primary"
                            themeType="outline"
                            onClick={handleApplyToAllModulesClick}
                            className={cn('rmd-button--text-large', 'grow')}
                            disabled={isApplyToAllModelsActive}
                            {...props}
                        />
                    )}
                />
            </FeatureOn>
        </FeatureToggle>
    );

    const confirm = (
        <FeatureContent
            key="add_to_cart"
            contentKey={calculationSectionId('add_to_cart')}
            fallback={t('buttons.confirm', 'Confirm')}
            wrapper={props => (
                <Button
                    theme="primary"
                    themeType="outline"
                    onClick={handleConfirmClick}
                    // disabled={isConfirmDisabled}
                    className={cn('rmd-button--text-large', 'w-full')}
                    {...props}
                />
            )}
        />
    );

    const actions = [
        isAlreadyInCart ? applyToAll : confirm,
        hasCurrentProduct && nextVisibleModel && (
            <FeatureContent
                key="next_model"
                contentKey={calculationSectionId('next_model')}
                fallback={t('buttons.nextModel', 'Next model')}
                wrapper={props => (
                    <Button
                        theme="secondary"
                        themeType="outline"
                        onClick={handleNextVisibleModelClick}
                        className={cn('rmd-button--text-large', 'grow')}
                        {...props}
                    />
                )}
            />
        ),
    ].filter(Boolean);

    return (
        <div>
            {isAlreadyInCart && (
                <Typography
                    id={calculationSectionId('already_in_cart')}
                    type="headline-6"
                    component="div"
                    className={cn(
                        'medium-margin-bottom',
                        'inline-flex',
                        'items-center',
                        'rmd-typography--theme-success',
                        styles.already,
                    )}
                >
                    <TextIconSpacing icon={<Icons.CheckCircleThin />} forceIconWrap>
                        <FeatureContent
                            contentKey={calculationSectionId('already_in_cart')}
                            fallback={t('buttons.alreadyInCart', 'Already in cart')}
                        />
                    </TextIconSpacing>
                </Typography>
            )}
            {!!actions.length && <div className={cn('emulated-flex-gap', styles.actions)}>{actions}</div>}
        </div>
    );
}

function CalculationInner() {
    const isConfirmDisabled = useAppSelector(selectIsProductConfirmDisabled);
    const isSameConfigAlreadyInCart = useAppSelector(selectIsSameConfigAlreadyInCart);
    const isSameQuantityInCart = useAppSelector(selectIsSameQuantityInCart);

    return (
        <SidebarSection span>
            <CalculationActions
                isAlreadyInCart={isSameQuantityInCart || isSameConfigAlreadyInCart}
                isConfirmDisabled={isConfirmDisabled}
            />
            <CalculationHints
                isConfirmDisabled={isConfirmDisabled}
                isSameConfigAlreadyInCart={isSameConfigAlreadyInCart}
                isSameQuantityInCart={isSameQuantityInCart}
            />
        </SidebarSection>
    );
}

export const CalculationDetailsLoader: React.FC<Omit<PreloaderBoxProps, 'show'>> = ({ children, ...rest }) => {
    const isOrderLoading = useAppSelector(selectIsOrderLoading);
    const isPricingLoading = useAppSelector(selectIsPricingLoading);

    const isOrderCreating = useAppSelector(selectIsOrderCreating);
    const isProductAdding = useAppSelector(selectProductIsAdding);

    return (
        <PreloaderBox
            // show
            show={isPricingLoading || isOrderLoading || isOrderCreating || isProductAdding}
            {...rest}
        >
            {children}
        </PreloaderBox>
    );
};

interface CalculationDetailsProps extends HTMLAttributes<HTMLDivElement> {
    model?: ObjectModel;
    technology?: Technology;
}

export const CalculationDetails: React.FC<CalculationDetailsProps> = ({ model, technology, ...rest }) => {
    const hasMaterial = useAppSelector(selectWidgetHasMaterial);
    const hasLoadingSuitable = useAppSelector(selectHasNotReadySuitableMaterial);
    const errors = useAppSelector(selectPricingErrors);

    const hasErrors = Boolean(errors?.length);
    const hasOrLoadingMaterial = hasLoadingSuitable || hasMaterial; // while a suitable one is loaded, we are not sure

    return (
        <CalculationDetailsLoader {...rest}>
            {technology && (hasErrors || !hasOrLoadingMaterial) ? (
                <CalculationErrors technology={technology} model={model} hasMaterial={hasMaterial} errors={errors} />
            ) : (
                <div>
                    <CalculationConfig />

                    <FeatureMarkup
                        contentKey={calculationSectionId('markup_under_quantity')}
                        wrapper={props => <SidebarSection span {...props} />}
                    />

                    <CalculationPricing show={hasMaterial} />
                    <CalculationInner />

                    <FeatureMarkup
                        contentKey={calculationSectionId('markup_bottom')}
                        wrapper={props => <SidebarSection span {...props} />}
                    />

                    {model && <AskForHelp model={model} />}
                </div>
            )}
        </CalculationDetailsLoader>
    );
};
