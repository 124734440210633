import { Remote, expose, wrap } from 'comlink';
import { store } from '@app/store';
import { selectIsOrderChanged } from '@modules/product';
import { transferModels } from './upload-models';

interface RemoteApi {
    saveOrder: () => void;
}

class ExposedApi {
    /*
     * Who opens this window?
     * https://stackoverflow.com/questions/11313045/what-are-window-opener-window-parent-window-top
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/opener
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/parent
     * */
    private initiator = window !== window.parent ? window.parent : window.opener;

    /*
     * If it is opened outside related pages, then there is no point in running the API
     * */
    public enabled = Boolean(this.initiator);

    /*
     * Message listening state
     * */
    public listening = false;

    /*
     * The two ports of the channel
     * https://developer.mozilla.org/en-US/docs/Web/API/Channel_Messaging_API
     * */
    private localPort?: MessagePort;
    private remotePort?: MessagePort;

    /*
     * API we provide through this application
     * */
    private localApi = {
        // get state() {
        state: () => {
            const _state = store.getState();
            return {
                isOrderChanged: selectIsOrderChanged(_state),
            };
        },
        transferModels,
    };

    /*
     * API of opposite side from related page
     * */
    private remoteApi?: Remote<RemoteApi>;

    constructor() {
        this.createChannel();

        this.saveOrder = this.saveOrder.bind(this);
    }

    private createChannel() {
        if (!this.enabled) return;

        const { port1, port2 } = new MessageChannel();
        this.localPort = port1; // for sending/receiving in our application
        this.remotePort = port2; // for sending/receiving in initiator application
    }

    /*
     * Send initiator a signal that we are ready to listen to calls
     * */
    private notifyInitiator() {
        this.initiator.postMessage(
            {
                status: 'ready',
                message: 'API is ready to listen for calls.',
            },
            '*',
            [this.remotePort!],
        );
    }

    /*
     * Listening to calls
     * */
    listen() {
        if (this.listening || !this.enabled) return;

        this.listening = true;
        this.notifyInitiator();

        // setup local api - listening to API calls and responding
        try {
            expose(this.localApi, this.localPort);
        } catch (_) {}
        // TODO catch errors 1) when calling undefined methods 2) throwIfProxyReleased

        // setup remote api - allows to call a remote API and process the response
        this.remoteApi = wrap<RemoteApi>(this.localPort!);
    }

    /*
     * Checking if a remote API is ready to be called, required if the initiator has exposed api
     * */
    public get isInitiatorReady() {
        return Boolean(this.listening && this.remoteApi);
    }

    /*
     * Initiator API method
     * */
    async saveOrder() {
        if (!this.isInitiatorReady) return;
        return await this.remoteApi!.saveOrder();
    }

    // type ExposedApiCall = string;
    // private processExposedCall() {}
}

export const exposedApi = new ExposedApi();
