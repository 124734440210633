import cn from 'classnames';
import React, { ReactNode } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { ObjectModel, Technology, PricingError, PricingErrorCode } from '@types';
import { getNodeIdComposer, isCNC } from '@utils';
import { AskForHelp } from '../ask-for-help';
import { RootPrefix } from '../constants';
import { ReactComponent as NotAvailable } from './error-unsupported.svg';
import { ReactComponent as WrongSize } from './error-size.svg';

import styles from './calculation-errors.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'calculation_errors');

enum CustomErrorCode {
    NoMaterial = 'no_material',
}

enum ErrorImage {
    Default = 'default',
    Size = 'size',
}

interface ErrorConfig {
    code: PricingError['code'] | `${CustomErrorCode}`;
    text: ReactNode;
    imageType?: ErrorImage;
}

const getErrorConfig = (error: PricingError, technologyId: number, t: TFunction): ErrorConfig => {
    const { code, text: responseText } = error;

    let text = '';
    let imageType: ErrorConfig['imageType'] = undefined;

    switch (code) {
        case PricingErrorCode.SanityFailed:
            text = responseText;
            break;
        case PricingErrorCode.ModelNotSuitable:
            text = t(
                'errors.modelNotSuitable',
                `The model doesn’t suit this technology. Please try changing your model or choose a different technology.`,
            );
            break;
        case PricingErrorCode.ModelToSmall:
            text = t(
                'errors.modelToSmall',
                `It looks like your model is too small for this technology. Please try changing your model or choose a different technology`,
            );
            imageType = ErrorImage.Size;
            break;
        case PricingErrorCode.SizesExceed:
            if (isCNC(technologyId)) {
                text = t(
                    'errors.sizesExceedCNC',
                    'It looks like your model is too big for this technology. Please try changing your model or choose a different technology',
                );
            } else {
                text = t(
                    'errors.sizesExceed3D',
                    'It looks like your model is too big for this technology. Please try changing your model or choose a different technology. You can also try rotating the model in the 3D Viewer so it could fit.',
                );
            }
            imageType = ErrorImage.Size;
            break;

        // case PricingErrorCode.ModelIsFailed:
        // case PricingErrorCode.ExpectedCuraIsMissing:
        //     break;

        default: {
            text = t(
                'errors.couldNotCalculatePrice',
                `It looks like we couldn't calculate the price. Please try changing your model or choose a different technology.`,
            );
        }
    }

    return { text, imageType, code };
};

interface CalculationErrorProps extends ErrorConfig {
    className?: string;
    imageClassName?: string;
    textClassName?: string;
}

const CalculationError: React.FC<CalculationErrorProps> = ({
    className,
    imageClassName,
    textClassName,
    code,
    text,
    imageType = ErrorImage.Default,
    children,
}) => {
    const id = _id(code);
    const Image = imageType === ErrorImage.Size ? WrongSize : NotAvailable;

    return (
        <div className={cn('base-paddings', 'rounded-box', styles.box, className)}>
            <Image className={cn(styles.img, imageClassName)} />
            <Typography id={id} type="body-1" component="div" className={cn(styles.text, textClassName)}>
                <FeatureContent contentKey={id} fallback={text} />
            </Typography>
            {children}
        </div>
    );
};

export interface CalculationErrorsProps {
    technology: Technology;
    model?: ObjectModel;
    hasMaterial: boolean;
    errors?: PricingError[];
}

export const CalculationErrors: React.FC<CalculationErrorsProps> = ({
    model,
    technology,
    hasMaterial,
    errors = [],
}) => {
    const { t } = useTranslation();

    const configs = errors.map(error => getErrorConfig(error, technology.tech_id, t));
    const isSingleError = errors.length === 1;
    const singleErrorConfig = hasMaterial
        ? configs[0]
        : {
              code: CustomErrorCode.NoMaterial,
              text: (
                  <Trans
                      i18nKey="errors.canNotBeProduced"
                      defaults={
                          'This part can’t be produced with this technology.<br/><br/>Please try changing your model or choose a different technology.'
                      }
                  />
              ),
          };

    const askHelp = model && <AskForHelp modelId={model.parent_model || model.id} />;

    return (
        <div className="base-paddings">
            {isSingleError || !hasMaterial ? (
                <CalculationError
                    {...singleErrorConfig}
                    className={styles.single}
                    textClassName="rmd-typography--center"
                >
                    {askHelp}
                </CalculationError>
            ) : (
                <>
                    {configs.map(config => (
                        <CalculationError key={config.code} {...config} className={styles.multiple} />
                    ))}
                    {askHelp}
                </>
            )}
        </div>
    );
};
