import attempt from 'lodash/attempt';
import isError from 'lodash/isError';

//----- It's to process error messages with JSON-like strings -----//

export function fixJson(text: string) {
    return text.replaceAll("'", '"');
}

export function defineIfJson(objectFromJson: any) {
    return !isError(objectFromJson);
}

export function jsonToObject(text: string) {
    const fixedJson = fixJson(text);
    const objectFromJson = attempt(JSON.parse, fixedJson);
    const isJson = defineIfJson(objectFromJson);
    return { objectFromJson, isJson };
}
