import cn from 'classnames';
import schemify from 'url-schemify/url-schemify';
import React, { memo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { getMediaUrl } from '@services/axios-instance';
import { FeatureKeys as FF, FeatureOff, FeatureOn, FeatureToggle } from '@components/feature-flags';
import { AppSettings } from '@types';
import { useGetOrderUrl } from '@pages/widget/order/hooks';
import { isAbsoluteUrl, isValidUrl, reverse } from '@utils';
import { IqtBanner } from './iqt-banner';
import { ReactComponent as DigifabsterLogo } from './logo.svg';

import styles from './header.module.scss';

interface LogoProps {
    className?: string;
    defaultUrl: NavLinkProps['to'];
    logoUrl?: string;
    website?: string;
}

const Logo = ({ className, defaultUrl, logoUrl, website }: LogoProps) => {
    const content = Boolean(logoUrl) ? (
        <img alt="Company Logo" src={isAbsoluteUrl(logoUrl!) ? logoUrl : getMediaUrl(logoUrl!)} />
    ) : (
        <DigifabsterLogo />
    );
    const parsedWebsiteUrl = website && schemify(website, { scheme: 'https' });

    return parsedWebsiteUrl && isValidUrl(parsedWebsiteUrl) ? (
        <a className={className} href={parsedWebsiteUrl}>
            {content}
        </a>
    ) : (
        <NavLink
            className={className}
            to={defaultUrl}
            style={({ isActive }) => ({ pointerEvents: isActive ? 'none' : 'auto' })}
        >
            {content}
        </NavLink>
    );
};

interface LogoBoxProps {
    settings: AppSettings;
}

export const LogoBox = memo<LogoBoxProps>(({ settings }) => {
    const [getOrderUrl, currentOrderId] = useGetOrderUrl();

    return (
        <FeatureToggle flagKey={FF.Header.LogoShown}>
            <FeatureOn>
                <div className={styles.logoBox} role="banner">
                    <Logo
                        className={cn('rmd-link', 'rmd-typography--theme-primary', styles.logo)}
                        defaultUrl={currentOrderId ? getOrderUrl() : reverse('widgetUpload')}
                        logoUrl={settings.logo_url}
                        website={settings.website}
                    />
                    <IqtBanner />
                </div>
            </FeatureOn>
            <FeatureOff>
                <div className={styles.logoBox} role="banner">
                    <IqtBanner />
                </div>
            </FeatureOff>
        </FeatureToggle>
    );
});
