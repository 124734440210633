import * as yup from 'yup';
import { getBaseStringValidator, getFirstNameValidator, getLastNameValidator, getPhoneValidator } from '@utils';

export const createAskHelpValidation = ({
    isFirstNameRequired = false,
    isLastNameRequired = false,
    isPhoneRequired = false,
}: {
    isFirstNameRequired: boolean;
    isLastNameRequired: boolean;
    isPhoneRequired: boolean;
}) => {
    const validator = getBaseStringValidator();
    const firstNameValidator = getFirstNameValidator({ required: isFirstNameRequired });
    const lastNameValidator = getLastNameValidator({ required: isLastNameRequired });
    const phoneValidator = getPhoneValidator({ required: isPhoneRequired, max: [50] });

    return yup.object({
        name: firstNameValidator,
        surname: lastNameValidator,
        phone_number: phoneValidator,
        text: validator.required(),
    });
};
