import type { AxiosRequestConfig } from 'axios';

type Params = AxiosRequestConfig['params'];
type ExtendQueryParams = (endpoint: string, params: Params) => Params;
type ExtendQueryArgs = (endpoint: string, params: Partial<AxiosRequestConfig>) => AxiosRequestConfig;

/*
    Utilities for modifying codegen endpoints
 */

export const extendQueryParams: ExtendQueryParams = (endpoint, params = {}) => {
    switch (endpoint) {
        case 'ordersRetrieve':
        case 'usersOrdersRetrieve': {
            return { ...params, expand: '~all' };
        }
        case 'ordersList':
        case 'usersOrdersList': {
            return {
                ...params,
                expand: ['applied_discount'],
            };
        }
        default: {
            return params;
        }
    }
};

export const extendQueryArgs: ExtendQueryArgs = (endpoint, args = {}) => {
    switch (endpoint) {
        // https://digifabster.atlassian.net/browse/DFDEV-3072
        case 'usersPurchasesList': {
            return { ...args, url: `/v2/users/purchases/optimized/` };
        }
        case 'authCreateSessionCreate':
        case 'authCreateSessionWithTokenCreate': {
            return { ...args, withCredentials: true };
        }
        default: {
            return args;
        }
    }
};
