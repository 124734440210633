import isObject from 'lodash/isObject';
import { FormState, FieldValues } from 'react-hook-form';

// https://github.com/orgs/react-hook-form/discussions/1991

export const getDirtyValues = <TFieldValues extends FieldValues>(
    dirtyFields: FormState<TFieldValues>['dirtyFields'] | true,
    allValues: TFieldValues,
): Partial<TFieldValues> => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    // @ts-ignore
    return Object.fromEntries(
        Object.entries(dirtyFields)
            .filter(([key, value]) =>
                Array.isArray(value)
                    ? value.some(element =>
                          isObject(element) ? Object.values(element).every(Boolean) : element,
                      )
                    : isObject(value)
                    ? Object.values(value).some(Boolean)
                    : Boolean(value),
            )
            .map(([key]) => [key, getDirtyValues(dirtyFields[key]!, allValues[key])]),
    );
};

/*
    Dirty fields example:

        {
           "tolerances": [
              {
                 "id": false,
                 "deviation_from": false,
                 "deviation_to": false,
                 "margin": false,
                 "name_for_user": false,
                 "sort_order": false,
                 "display_name": false
              }
           ],
           "features": [],
           "bracketing": {
              "id": false,
              "first_multiplier": false,
              "second_multiplier": false,
              "third_multiplier": false,
              "fourth_multiplier": false,
              "fifth_multiplier": false,
              "first_boundary": false,
              "second_boundary": false,
              "third_boundary": false,
              "fourth_boundary": false
           },
           "machine_speed": false,
           "min_size_z": false,
           "technology": false,
           "active": false,
           "organizations_enabled": [
              {
                 "id": false,
                 "title": false,
                 "domain": false
              },
              {
                 "id": true,
                 "title": true,
                 "domain": true
              }
           ],
           "suitable_materials": [
              false,
              false,
              false,
              false
           ],
           "features_operator_involvement": false,
           "machining_operator_involvement": false,
           "custom_tech": false,
           "printer_preset": false
        }

*/
