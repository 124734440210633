import qs from 'qs';
import { AxiosRequestConfig } from 'axios';
import { Store } from 'redux';
import { ObjectModelModel } from '@models';
import { uploadModelsActions } from '@modules/upload-models';
import {
    UploadModelsRequest,
    UploadJobResponse,
    UploadModelsResponse,
    PagedResponse,
    ObjectModel,
    ObjectModelStatus,
    UpdateModelPayload,
    UpdateNonCadModelPayload,
    ScaleModelPayload,
    RotateModelPayload,
    LoadModelsParams,
    Preselection,
    SuitableMaterials,
    PreselectionPayload,
    SuitableMaterialsPayload,
} from '@types';
import { PaginationConfig } from '@constants';
import { axiosInstance } from './axios-instance';

export class ModelsService {
    static store: Store;

    static init() {
        return new ModelsService();
    }

    // requests
    createUploadJob() {
        return axiosInstance.post<UploadJobResponse>('/v2/upload_job/', {});
    }

    rebindUploadJob(uploadJobId: string) {
        return axiosInstance.post<UploadJobResponse>('/v2/upload_job/re_bind/', { upload_job_id: uploadJobId });
    }

    createUploadJobIqt(userId: number) {
        return axiosInstance.post<UploadJobResponse>(`/v2/users/${userId}/upload_job/`, {});
    }

    uploadModels({ uj, upload }: UploadModelsRequest) {
        const formData = new FormData();
        const models = upload.map(file => file.file);

        models.forEach(modelFile => {
            formData.append('models', modelFile);
        });

        formData.append('upload_job_id', uj);

        return axiosInstance.post<UploadModelsResponse>('/v2/upload_models/', formData, {
            onUploadProgress: progressEvent => {
                const { progress: _progress } = progressEvent;
                const progress = _progress ? Math.floor(_progress * 100) : 0;

                progress &&
                    ModelsService.store.dispatch(
                        uploadModelsActions.updateProgress({
                            UUIDs: upload.map(file => file.uuid),
                            progress,
                        }),
                    );
            },
        });
    }

    checkStatus(modelsIds: number[], config?: AxiosRequestConfig) {
        return axiosInstance.get<Record<number, ObjectModelStatus>>('/v2/model_status/', {
            params: {
                model_id: modelsIds,
                format: 'json',
            },
            paramsSerializer: params => {
                return qs.stringify(params, { indices: false });
            },
            ...config,
        });
    }

    loadModels(params?: LoadModelsParams, iqt = false, config?: AxiosRequestConfig) {
        return axiosInstance.get<PagedResponse<ObjectModel>>(iqt ? '/v2/users/models/' : '/v2/models/', {
            params: {
                fields: ObjectModelModel.usedFields.join(','),
                limit: PaginationConfig.pageSize,
                format: 'json',
                ...params,
            },
            ...config,
        });
    }

    // https://stackoverflow.com/a/67622804/12543541
    updateModel(modelId: ObjectModel['id'], data: UpdateModelPayload | UpdateNonCadModelPayload, iqt = false) {
        return axiosInstance.patch<ObjectModel>(iqt ? `/v2/users/models/${modelId}/` : `/v2/models/${modelId}/`, data);
    }

    loadPreselection({ modelsIds, isIqtModeOn = false, config }: PreselectionPayload) {
        return axiosInstance.post<Record<string, Preselection>>(
            isIqtModeOn ? 'v2/users/preselection/' : '/v2/preselection/',
            {
                models_ids: modelsIds,
            },
            config,
        );
    }

    loadSuitableMaterials({ modelsIds, isIqtModeOn = false, config }: SuitableMaterialsPayload) {
        return axiosInstance.post<Record<string, SuitableMaterials>>(
            isIqtModeOn ? 'v2/users/suitable_materials/' : '/v2/suitable_materials/',
            {
                models_ids: modelsIds,
            },
            config,
        );
    }

    scale({ fromModelId, units, isIqtModeOn = false }: ScaleModelPayload) {
        return axiosInstance.post<UploadModelsResponse>(
            isIqtModeOn ? `/v2/users/models/${fromModelId}/scale/` : `/v2/models/${fromModelId}/scale/`,
            { units },
        );
    }

    rotate({ fromModelId, rotations, isIqtModeOn = false }: RotateModelPayload) {
        return axiosInstance.post<UploadModelsResponse>(
            isIqtModeOn ? `/v2/users/models/${fromModelId}/rotate/` : `/v2/models/${fromModelId}/rotate/`,
            { actions: rotations },
        );
    }
}
