import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useAppSelector } from '@app/hooks';
import { selectContentCustomization } from '@modules/app';
import { RootState } from '@types';
import { getFeatureFlag, getFeatureFlags } from './helpers';

export const useFeatureFlag = (flagKey: string) => {
    const content = useAppSelector(selectContentCustomization);
    return getFeatureFlag(content, flagKey);
};

export const makeSelectFeatureFlags = () =>
    createSelector(
        (state: RootState, flagKeys: string[]) => flagKeys,
        selectContentCustomization,
        (flagKeys, content) => getFeatureFlags(content, flagKeys),
    );

export const useFeatureFlags = (flagKeys: string[]) => {
    const selectFeatureFlags = useMemo(makeSelectFeatureFlags, []);
    return useAppSelector(state => selectFeatureFlags(state, flagKeys));
};
