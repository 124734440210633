import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        messagesList: build.query<MessagesListApiResponse, MessagesListApiArg>({
            query: queryArg => ({
                url: `/v2/messages/`,
                params: {
                    created_at: queryArg.createdAt,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    order_id__in: queryArg.orderIdIn,
                    ordering: queryArg.ordering,
                    search: queryArg.search,
                    source: queryArg.source,
                    upload_job_id: queryArg.uploadJobId,
                    user_id__in: queryArg.userIdIn,
                },
            }),
        }),
        messagesCreate: build.mutation<MessagesCreateApiResponse, MessagesCreateApiArg>({
            query: queryArg => ({
                url: `/v2/messages/`,
                method: 'POST',
                body: queryArg.createManualReviewCommentRequest,
            }),
        }),
        messagesRetrieve: build.query<MessagesRetrieveApiResponse, MessagesRetrieveApiArg>({
            query: queryArg => ({ url: `/v2/messages/${queryArg.id}/` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type MessagesListApiResponse = /** status 200  */ PaginatedCommentList;
export type MessagesListApiArg = {
    createdAt?: string;
    dateFrom?: string;
    dateTo?: string;
    /** Number of results to return per page. */
    limit?: number;
    /** The initial index from which to return the results. */
    offset?: number;
    /** Multiple values may be separated by commas. */
    orderIdIn?: number[];
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A search term. */
    search?: string;
    /** Multiple values may be separated by commas.
    
    * `email` - Email
    * `auto` - Auto
    * `manual_review` - Manual Review */
    source?: ('auto' | 'email' | 'manual_review')[];
    uploadJobId?: string | null;
    /** Multiple values may be separated by commas. */
    userIdIn?: number[];
};
export type MessagesCreateApiResponse = /** status 201  */ DetailedComment;
export type MessagesCreateApiArg = {
    createManualReviewCommentRequest: CreateManualReviewCommentRequest;
};
export type MessagesRetrieveApiResponse = /** status 200  */ DetailedComment;
export type MessagesRetrieveApiArg = {
    id: number;
};
export type SourceEnum = 'email' | 'auto' | 'manual_review';
export type Comment = {
    id: number;
    source: SourceEnum;
    sender?: string;
    subject?: string;
    text?: string;
    raw_data?: any;
    created_at: string;
    updated_at: string;
    order?: number | null;
    user?: number | null;
    upload_job?: string | null;
};
export type PaginatedCommentList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Comment[];
};
export type CommentAttachment = {
    url: string;
    name: string;
    created_at: string;
    updated_at: string;
};
export type CommentContextModel = {
    id: number;
    title: string;
};
export type DetailedComment = {
    id: number;
    attachments: CommentAttachment[];
    context_models: CommentContextModel[];
    source: SourceEnum;
    sender?: string;
    subject?: string;
    text?: string;
    raw_data?: any;
    created_at: string;
    updated_at: string;
    order?: number | null;
    user?: number | null;
    upload_job?: string | null;
};
export type CreateManualReviewCommentRequest = {
    order?: number | null;
    upload_job?: string | null;
    text?: string;
    model_ids?: number[];
    attachments?: Blob[];
};
export const { useMessagesListQuery, useMessagesCreateMutation, useMessagesRetrieveQuery } = injectedRtkApi;
