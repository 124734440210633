// import isUndefined from 'lodash/isUndefined';
import { enhancedApi as api } from './codegen';

api.enhanceEndpoints({
    endpoints: {
        clientsRetrieve: {
            providesTags: (result, error, arg) => [{ type: 'Client', id: arg.id }],
        },
        clientsMeRetrieve: {
            providesTags: (result, error) => [{ type: 'Client', id: 'Me' }],
        },
        clientsList: {
            providesTags: (result, error) => [{ type: 'Client', id: 'LIST' }],
        },
        clientsCreate: {
            invalidatesTags: (result, error) => [{ type: 'Client' }],
        },
        // clientsPartialUpdate: {
        //     // don't refetch clients if we simply add/remove an organization,
        //     // this breaks src/modules/organizations/details/organization-users/organization-users.tsx
        //     invalidatesTags: (result, error, query) =>
        //         isUndefined(query.patchedUpdateUserRequest.organization_id)
        //             ? [{ type: 'Client', id: 'LIST' }]
        //             : [],
        //     async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        //         try {
        //             const { data } = await queryFulfilled;
        //             dispatch(
        //                 api.util.updateQueryData('clientsRetrieve', { id }, draft => {
        //                     Object.assign(draft, data);
        //                 }),
        //             );
        //         } catch {}
        //     },
        // },
        clientsMePartialUpdate: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('clientsMeRetrieve', undefined, draft => {
                            Object.assign(draft, data);
                        }),
                    );
                } catch {}
            },
        },
    },
});
