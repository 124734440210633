import cn from 'classnames';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { PaymentIntent } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { PaymentService } from '@services';
import { PaymentMethod } from '@services/df/codegen-enums';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@react-md/button';
import { Typography } from '@react-md/typography';
import {
    getDefaultStripeOptions,
    StripeElementsAcssButton,
    StripeElementsNextAction,
    StripeBankTransferInstruction,
    StripeElementsPopup,
} from '@components/stripe-elements';
import { FeatureContent, FeatureContentCompound, ContentKeys as FC } from '@components/feature-content';
import { Price } from '@components/price';
import { Invoice, PaymentStage, DataForIPN, DataForITN, DataForConverge } from '@types';
import { AppSession, getNodeIdComposer, customVariables as CV } from '@utils';
import { selectStripePromise } from '@modules/app';
import { invoiceActions, selectInvoicePayment, selectIsInvoicePaymentModalOpen } from '@modules/invoice';
import { sidebarId } from './helpers';
import { RootPrefix } from './constants';

const popupId = getNodeIdComposer(RootPrefix, 'payment_popup');

function useAppModeContent(isIFrame: boolean) {
    const { t } = useTranslation();

    return isIFrame
        ? {
              formTarget: '_blank',
              submitText: t('buttons.payInNewTab', 'Pay in the new tab'),
          }
        : {
              formTarget: '_self',
          };
}

function renderFields(fields: { [key: string]: string | number | readonly string[] | undefined }) {
    return Object.entries(fields).map(([name, value]) => (
        <input key={name} type="hidden" name={name} defaultValue={value} />
    ));
}

interface InvoicePaymentButtonProps extends ButtonProps {}

export const InvoicePaymentButton = ({ children, className, ...rest }: InvoicePaymentButtonProps) => {
    const { t } = useTranslation();

    return (
        <FeatureContent
            contentKey={sidebarId('pay_now')}
            fallback={children ?? t('buttons.payNow', 'Pay now')}
            wrapper={props => (
                <Button
                    theme="primary"
                    themeType="contained"
                    className={cn('rmd-button--text-large', 'w-full', className)}
                    {...rest}
                    {...props}
                />
            )}
        />
    );
};

interface InvoicePaymentProviderProps {
    invoice: Invoice;
    invoiceId: string;
    hash: string;
}

export const InvoiceStripe: React.FC<InvoicePaymentProviderProps> = ({ invoice, invoiceId, hash }) => {
    const dispatch = useAppDispatch();
    const stripePromise = useAppSelector(selectStripePromise);
    const invoicePayment = useAppSelector(selectInvoicePayment);
    const isPaymentPopupOpen = useAppSelector(selectIsInvoicePaymentModalOpen);

    const openPaymentPopup = useCallback(() => {
        dispatch(invoiceActions.openPaymentModal());
    }, [dispatch]);
    const closePaymentPopup = useCallback(() => {
        dispatch(invoiceActions.closePaymentModal());
    }, [dispatch]);

    const updatePaymentStage = useCallback(
        paymentIntent => {
            const payload = {
                nextAction: paymentIntent.next_action,
            };

            switch (paymentIntent.status) {
                case 'succeeded':
                    dispatch(
                        invoiceActions.updatePaymentStage({
                            ...payload,
                            status: PaymentStage.Paid,
                        }),
                    );
                    return;
                case 'processing':
                    dispatch(
                        invoiceActions.updatePaymentStage({
                            ...payload,
                            status: PaymentStage.Processing,
                        }),
                    );
                    return;
                default:
                    dispatch(invoiceActions.updatePaymentStage(payload));
            }
        },
        [dispatch],
    );
    const onSuccessPaymentElement = useCallback(
        (paymentIntent: PaymentIntent) => {
            closePaymentPopup();
            updatePaymentStage(paymentIntent);
        },
        [closePaymentPopup, updatePaymentStage],
    );
    const onSuccessAcssPayment = useCallback(
        (paymentIntent: PaymentIntent) => {
            updatePaymentStage(paymentIntent);
        },
        [updatePaymentStage],
    );

    // get or create InvoicePayment
    useEffect(() => {
        if (!invoicePayment) {
            dispatch(invoiceActions.loadPayment({ invoiceId, hash }));
        }
    }, [dispatch, invoicePayment, invoiceId, hash]);

    const clientSecret = invoicePayment?.secret;
    const nextAction = invoicePayment?.next_action;
    // const clientSecret = 'pi_3LFBXkIIpTIg11XJ0rDtaucg_secret_3igiDpYDlSt2lfNoEzQBYsnBq';
    const hasAcssPayment = invoicePayment?.payment_methods.includes('acss_debit');

    const { t } = useTranslation();

    if (!clientSecret) return null;

    const title = (
        <Typography id={popupId('title')} type="headline-3" component="div">
            <FeatureContentCompound
                contentKey={popupId('title')}
                i18nKey="titles.paymentForOrder"
                defaults={`Payment for order #{{ orderId }}`}
                values={{
                    orderId: invoice?.order.company_order_id,
                }}
            />
        </Typography>
    );
    const subtitle = (
        <Typography id={popupId('subtitle')} type="body-2" component="div" className="small-margin-top">
            <FeatureContent
                contentKey={popupId('subtitle')}
                fallback={
                    <>
                        {t('labels.total', 'Total')}
                        {': '}
                        {<Price>{invoice?.order.total_price}</Price>}
                    </>
                }
            />
        </Typography>
    );

    return (
        <Elements stripe={stripePromise} options={{ ...getDefaultStripeOptions(), clientSecret }}>
            <StripeElementsPopup
                title={title}
                subtitle={subtitle}
                isOpen={isPaymentPopupOpen}
                close={closePaymentPopup}
                onSuccess={onSuccessPaymentElement}
                options={CV.StripePaymentElementOptions}
            />

            {/*in this case paymentIntent status is 'requires_action' or 'requires_source_action' for old api version*/}
            {/* check invoicePayment status is 'created' or 'partially_funded' */}
            {nextAction && <StripeElementsNextAction action={nextAction} />}

            <InvoicePaymentButton onClick={openPaymentPopup} />

            {hasAcssPayment && (
                <StripeElementsAcssButton clientSecret={clientSecret} onSuccess={onSuccessAcssPayment} />
            )}
        </Elements>
    );
};

export const InvoicePayPal: React.FC<InvoicePaymentProviderProps> = ({ invoice, invoiceId, hash }) => {
    const appModeContent = useAppModeContent(AppSession.iFrame);
    const [dataForPayPal, setDataForPayPal] = useState<DataForIPN>();

    useLayoutEffect(() => {
        PaymentService.init()
            .getDataForIPN(invoiceId, hash)
            .then(res => {
                setDataForPayPal(res.data);
            });
    }, [invoiceId, hash]);

    const fields = {
        cmd: '_xclick',
        business: dataForPayPal?.business,
        item_name: `Order #${invoice.order.company_order_id}`,
        amount: invoice.cost,
        no_shipping: dataForPayPal?.no_shipping,
        return: dataForPayPal?.return_url,
        notify_url: dataForPayPal?.notify_url,
        invoice: invoice.uuid,
        currency_code: invoice.order.currency.toUpperCase(),
    };

    return (
        <form
            className="fieldset-grid"
            action={dataForPayPal?.paypal_btn_url}
            target={appModeContent.formTarget}
            method="POST"
        >
            {renderFields(fields)}
            <InvoicePaymentButton type="submit">{appModeContent.submitText}</InvoicePaymentButton>
        </form>
    );
};

const PAYFAST_ERROR = 'Something went wrong with Payfast';

export const InvoicePayFast: React.FC<InvoicePaymentProviderProps> = ({ invoiceId, hash }) => {
    const appModeContent = useAppModeContent(AppSession.iFrame);
    const [dataForPayFast, setDataForPayFast] = useState<DataForITN>();
    const [error, setError] = useState<string>('');

    const {
        merchant_id,
        merchant_key,
        cancel_url,
        return_url,
        notify_url,
        m_payment_id,
        amount,
        item_name,
        signature,
        payfast_btn_url,
    } = dataForPayFast || {};

    useLayoutEffect(() => {
        PaymentService.init()
            .getDataForITN(invoiceId, hash)
            .then(res => {
                setDataForPayFast(res.data);
            })
            .catch(() => {
                console.error(PAYFAST_ERROR);
                setError(PAYFAST_ERROR);
            });
    }, [invoiceId, hash]);

    const fields = {
        merchant_id,
        merchant_key,
        cancel_url,
        return_url,
        notify_url,
        m_payment_id,
        amount,
        item_name,
        signature,
    };

    return Boolean(error) ? null : (
        <form className="fieldset-grid" action={payfast_btn_url} target={appModeContent.formTarget} method="POST">
            {renderFields(fields)}
            <InvoicePaymentButton type="submit">{appModeContent.submitText}</InvoicePaymentButton>
        </form>
    );
};

const CONVERGE_ERROR = 'Something went wrong with Converge';

export const InvoiceConverge: React.FC<InvoicePaymentProviderProps> = ({ invoiceId, hash }) => {
    const appModeContent = useAppModeContent(AppSession.iFrame);

    const [dataForConverge, setDataForConverge] = useState<DataForConverge>();
    const [error, setError] = useState<string>('');

    const { ssl_txn_auth_token, converge_btn_url } = dataForConverge || {};

    useLayoutEffect(() => {
        PaymentService.init()
            .getDataForConverge(invoiceId, hash)
            .then(res => {
                setDataForConverge(res.data);
            })
            .catch(() => {
                console.error(CONVERGE_ERROR);
                setError(CONVERGE_ERROR);
            });
    }, [invoiceId, hash]);

    const fields = { ssl_txn_auth_token };

    return Boolean(error) ? null : (
        <form className="fieldset-grid" action={converge_btn_url} target={appModeContent.formTarget} method="POST">
            {renderFields(fields)}
            <InvoicePaymentButton type="submit">{appModeContent.submitText}</InvoicePaymentButton>
        </form>
    );
};

interface InvoicePaymentManagerProps extends InvoicePaymentProviderProps {
    paymentMethod: PaymentMethod;
}

export const InvoicePaymentManager = ({ paymentMethod, ...rest }: InvoicePaymentManagerProps) => {
    switch (paymentMethod) {
        case PaymentMethod.Paypal: {
            return <InvoicePayPal {...rest} />;
        }
        case PaymentMethod.Payfast: {
            return <InvoicePayFast {...rest} />;
        }
        case PaymentMethod.Stripe: {
            return <InvoiceStripe {...rest} />;
        }
        case PaymentMethod.Converge: {
            return <InvoiceConverge {...rest} />;
        }
        default:
            return null;
    }
};
