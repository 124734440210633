import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostProductionPricingResult, Product, PostProductionPricing } from '@types';

interface State {
    data?: PostProductionPricing;
    isLoading: boolean;
}

const initialState: State = {
    data: {},
    isLoading: false,
};

export const postProductionPricingSlice = createSlice({
    name: 'postProductionPricing',
    initialState,
    reducers: {
        load: (state, action: PayloadAction<Pick<Product, 'model_id' | 'material_id'> & { thickness_id?: string }>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<PostProductionPricingResult['items']>) => {
            state.isLoading = false;
            state.data = {
                ...state.data,
                ...action.payload.reduce(
                    (acc, { uuid, price, minimal_price, price_per_item, setup_cost, price_multiplier }) => ({
                        ...acc,
                        [uuid]: { price, minimal_price, price_per_item, setup_cost, price_multiplier },
                    }),
                    {},
                ),
            };
        },
        loadFailure: state => {
            state.isLoading = false;
        },
    },
});

export const postProductionPricingActions = postProductionPricingSlice.actions;
