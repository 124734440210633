import axios from 'axios';
import partial from 'lodash/partial';
import schemify from 'url-schemify/url-schemify';
import { buildUrl } from '@utils';
import { AppSettings } from '@types';

export const baseURL: string = (() => {
    const environment = process.env.NODE_ENV;

    switch (environment) {
        case 'test':
            return window.location.origin;
        default:
            return process.env.REACT_APP_DF_API_BASE_URL;
    }
})();

export const getBackendUrl = partial(buildUrl('/'), baseURL);
export const getMediaUrl = partial(buildUrl(), baseURL);

export function getCustomBackendUrl(relativePath: string, clientDomain: AppSettings['custom_domain']) {
    if (clientDomain !== null) {
        return schemify(clientDomain + relativePath, { scheme: 'https' });
    } else {
        return getBackendUrl(relativePath);
    }
}

export const axiosInstance = axios.create({
    baseURL,
    headers: {
        accept: 'application/json',
    },
});
