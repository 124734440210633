import { generatePath } from 'react-router';
import { ROUTES, IQT_MODE_ROUTES, SHORT_IQT_MODE_ROUTES, RoutesPatterns, RoutesNames } from '@constants';
import { AppMode } from '@types';
import { AppSession } from '../app-session';

export function reverse<Path extends RoutesPatterns>(
    name: RoutesNames,
    params?: Parameters<typeof generatePath>[1],
    mode = AppSession.mode,
) {
    let path = ROUTES[name as keyof typeof ROUTES];
    const baseParams = { company: AppSession.companyName };

    switch (mode) {
        case AppMode.Iqt: {
            // @ts-ignore
            path = IQT_MODE_ROUTES[name as keyof typeof IQT_MODE_ROUTES] ?? path;
            break;
        }
        case AppMode.ShortIqt: {
            // @ts-ignore
            path = SHORT_IQT_MODE_ROUTES[name as keyof typeof SHORT_IQT_MODE_ROUTES] ?? path;
            break;
        }
    }

    // @ts-ignore
    return generatePath<Path>(path, { ...baseParams, ...params });
}

export const isAbsoluteUrl = (url: string) => /^[a-z][a-z0-9+.-]*:/.test(url);

export function isValidUrl(url: string) {
    try {
        return Boolean(new URL(url));
    } catch (_) {
        return false;
    }
}

export interface BuildOrderUrlProps {
    page?: 'widgetOrder' | 'widgetOrderSubmit';
    orderId?: number;
}

export function buildOrderUrl({ orderId: _orderId, page = 'widgetOrder' }: BuildOrderUrlProps) {
    const orderId = _orderId && _orderId.toString();
    return reverse(page, orderId ? { orderId } : {});
}

export function replaceUrl(url: string) {
    // native replace to avoid rerender
    window.history.replaceState(null, '', url);
}
