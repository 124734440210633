import { AppThunk, ObjectModel } from '@types';
import { modelsActions, selectParentModelsList } from '../models';
import { quotationActions } from './slice';
import { selectProductByModel, selectWidgetNextModel } from './selectors';

export const removeModel =
    ({ model, isCurrentModel }: { model: ObjectModel; isCurrentModel: boolean }): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const models = selectParentModelsList(state);

        const modelId = model.id;
        const modelProduct = selectProductByModel(state, model);

        // cannot be removed, just hide the model if it has an associated product
        if (modelProduct) {
            dispatch(
                quotationActions.hideModel({
                    modelId,
                    modelProduct,
                }),
            );
            return;
        }

        const isLastModel = models.length === 1;

        if (isLastModel) {
            dispatch(quotationActions.resetCurrentModel());
        } else if (isCurrentModel) {
            // try to choose another one if the current one is deleted
            const nextModel = selectWidgetNextModel(state, modelId);

            if (nextModel) {
                const nextProduct = selectProductByModel(state, nextModel);

                if (nextProduct) {
                    dispatch(quotationActions.setProduct(nextProduct));
                } else {
                    dispatch(quotationActions.setModel({ modelId: nextModel.id }));
                }
            } else {
                // we have in progress preselection
                dispatch(quotationActions.resetCurrentModel());
            }
        }

        dispatch(modelsActions.removeSelectedModel(modelId));
    };
