import isUndefined from 'lodash/isUndefined';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthPopup, PopupType, PopupOpenPayload, RootState } from '@types';

interface State {
    isOpen: boolean;
    isClosable?: boolean;
    type: PopupType;
}

const initialState: State = {
    isOpen: false,
    type: AuthPopup.SignIn,
};

export const popupsSlice = createSlice({
    name: 'popups',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<PopupOpenPayload>) => {
            state.isOpen = true;

            // cases when we switch between two pop-up windows, for example SignIn/SignUp
            // we want to preserve initial props of popupsActions.open
            if (!isUndefined(action.payload.isClosable)) {
                state.isClosable = action.payload.isClosable;
            }

            state.type = action.payload.type;
        },
        close: state => {
            state.isOpen = false;
            state.isClosable = undefined;
        },
    },
});

export const popupsActions = popupsSlice.actions;

export const selectPopupState = (state: RootState) => state.ui.popups;
