import * as yup from 'yup';
import { AppSettings, FieldGroups } from '@types';
import {
    extractFields,
    getBaseStringValidator,
    getRequiredStringValidator,
    getEmailValidator,
    getPhoneValidator,
} from '@utils';
import { FieldTypes } from '@constants';
import { isDeliveryAddressVisible } from './helpers';

type yupContext = yup.TestContext<Record<string, unknown>> & { from: any[] };
// type yupContext = yup.TestContext<object> & {
//     from?: [
//       {
//         schema: yup.ObjectSchema;
//         value: BillingAddress;
//       },
//       {
//         schema: yup.ObjectSchema;
//         value: OrderForm;
//       }
//     ];
//   };

export const createOrderValidation = (settings: AppSettings, fieldGroups: FieldGroups[] | undefined) => {
    const yupBaseStr = getBaseStringValidator();
    const dynamicFields = extractFields(fieldGroups);

    const billingAddressRequiredValidator = (base: any) => {
        return base.test('required', undefined, (val: string, context: yupContext) => {
            const { isCompanyOrder } = context.from[1].value;
            return isCompanyOrder ? context.schema.required().isValidSync(val) : true;
        });
    };

    const billingContactRequiredValidator = (base: any) => {
        return base.test('required', undefined, (val: string, context: yupContext) => {
            const { isCompanyOrder } = context.from[0].value;
            return isCompanyOrder ? context.schema.required().isValidSync(val) : true;
        });
    };

    const deliveryAddressRequiredValidator = (base: any) => {
        return base.test('required', undefined, (val: string, context: yupContext) => {
            const { shippingType } = context.from[1].value;
            return isDeliveryAddressVisible(shippingType, settings) ? context.schema.required().isValidSync(val) : true;
        });
    };

    return yup.object({
        isCompanyOrder: yup.bool(),
        shippingType: yup.mixed().oneOf([0, 1], { key: 'errors.chooseDeliveryType' }),

        first_name: yupBaseStr.max(255).required(),
        last_name: yupBaseStr.max(255).required(),
        //------ Max limit 20 digit was added because stripe refuses to accept longer phone numbers in certain cases ------//
        phone: getPhoneValidator({ required: true, max: [20] }),
        email: getEmailValidator(),

        billing_name: billingContactRequiredValidator(yupBaseStr.max(255)),
        billing_surname: billingContactRequiredValidator(yupBaseStr.max(255)),
        billing_email: billingContactRequiredValidator(getEmailValidator({ required: false })),
        //------ Max limit 20 digit was added because stripe refuses to accept longer phone numbers in certain cases ------//
        billing_phone: getPhoneValidator({ max: [20] }),

        custom_fields: yup.lazy((value: { [key: string]: unknown }) => {
            if (value && dynamicFields) {
                const rules = {} as { [key: string]: yup.AnySchema };

                Object.keys(value).forEach(key => {
                    const field = dynamicFields.find(({ objectName }) => objectName === key);

                    switch (field?.JSONSchema?.type) {
                        case FieldTypes.String: {
                            rules[key] = field.isRequired ? yup.string().required() : yup.string().notRequired();
                            break;
                        }

                        case FieldTypes.Number: {
                            rules[key] = field.isRequired ? yup.number().required() : yup.number().notRequired();
                            break;
                        }

                        case FieldTypes.Select: {
                            rules[key] = field.isRequired ? yup.mixed().required() : yup.mixed().notRequired();
                            break;
                        }

                        case FieldTypes.Checkbox: {
                            rules[key] = field.isRequired
                                ? yup.boolean().oneOf([true], { key: 'errors.required' })
                                : yup.boolean().notRequired();
                            break;
                        }
                        default:
                            break;
                    }
                });

                return yup.object().shape(rules);
            }

            return yup.mixed().notRequired();
        }),

        billing_address: yup.object({
            company_name: yupBaseStr.max(100),
            // vat_number: yupBaseStr.max(30).when("isCompanyOrder", {
            //     is: (isCompanyOrder: boolean) => isCompanyOrder && settings.vat_number_required,
            //     then: yupBaseStr.max(30).required()
            // }),
            // @ts-ignore
            vat_number: yupBaseStr.max(30).test('required', undefined, (val: string, context: yupContext) => {
                const { isCompanyOrder } = context.from[1].value;
                return isCompanyOrder && settings.vat_number_required
                    ? context.schema.required().isValidSync(val)
                    : true;
            }),
            city: billingAddressRequiredValidator(yupBaseStr.max(50)),
            street_address: billingAddressRequiredValidator(yupBaseStr.max(200)),
            apartment: yupBaseStr.max(50),
            postcode: billingAddressRequiredValidator(yupBaseStr.max(20)),
            country: billingAddressRequiredValidator(yupBaseStr),
            // state: yupBaseStr.when(["country", "isCompanyOrder"], {
            //     is: (country: string, isCompanyOrder: boolean) => {
            //         console.log(country, isCompanyOrder);
            //         return ["CA", "US"].includes(country) && isCompanyOrder
            //     },
            //     then: yupBaseStr.required()
            // }),
            // @ts-ignore
            state: yupBaseStr.test('required', undefined, (val: string, context: yupContext) => {
                const { isCompanyOrder } = context.from[1].value;
                return ['CA', 'US'].includes(context.parent.country) && isCompanyOrder
                    ? context.schema.required().isValidSync(val)
                    : true;
            }),
        }),

        delivery_address: yup.object({
            company_name: yupBaseStr.max(100),
            city: deliveryAddressRequiredValidator(yupBaseStr.max(50)),
            street_address: deliveryAddressRequiredValidator(yupBaseStr.max(200)),
            apartment: yupBaseStr.max(50),
            postcode: deliveryAddressRequiredValidator(yupBaseStr.max(20)),
            country: deliveryAddressRequiredValidator(yupBaseStr),
            // state: yupBaseStr.when(["country", "isSameAsBilling", "shippingType"], {
            //     is: (country: string, isSameAsBilling: boolean, shippingType: ShippingTypes) =>
            //         ["CA", "US"].includes(country) && isDeliveryAddressVisible({ isSameAsBilling, shippingType }, settings),
            //     then: yupBaseStr.required()
            // }),
            // @ts-ignore
            state: yupBaseStr.test('required', undefined, (val: string, context: yupContext) => {
                const { shippingType } = context.from[1].value;
                return ['CA', 'US'].includes(context.parent.country) && isDeliveryAddressVisible(shippingType, settings)
                    ? context.schema.required().isValidSync(val)
                    : true;
            }),
        }),

        notes: yup.string(),
    });
};

export const createRatesValidation = () => {
    const yupBaseStr = getBaseStringValidator();
    // const yupBaseStr = getBaseStringValidator().transform((cv: unknown, ov: unknown) =>
    //   typeof ov === "string" && ov.trim() === "" ? undefined : cv
    // ); // TODO
    const requiredKey = {
        key: 'errors.requiredForShippingRates',
    };
    const requiredValidator = yupBaseStr.required(requiredKey);

    return yup.object({
        first_name: requiredValidator.max(255),
        last_name: requiredValidator.max(255),
        phone: getPhoneValidator({
            required: requiredKey,
        }),
        delivery_address: yup.object({
            company_name: yupBaseStr.max(100),
            city: requiredValidator.max(50),
            street_address: requiredValidator.max(200),
            apartment: yupBaseStr.max(50),
            postcode: requiredValidator.max(20),
            country: requiredValidator,
            state: yupBaseStr.when('country', {
                is: (country: string) => ['CA', 'US'].includes(country),
                then: requiredValidator,
            }),
        }),
    });
};
