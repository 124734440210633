import cn from 'classnames';
import pick from 'lodash/pick';
import React, { useEffect, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToggle, bem } from '@react-md/utils';
import { Dialog, DialogHeader, DialogContent } from '@react-md/dialog';
import { Button } from '@react-md/button';
import { Typography, TextWeight } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Checkbox, TextField, TextArea, useIndeterminateChecked } from '@react-md/form';
import { FeatureContent } from '@components/feature-content';
import { FeatureKeys as FF, useFeatureFlags } from '@components/feature-flags';
import { FormField } from '@components/form-field';
import { FileField } from '@components/file-field';
import { Icons } from '@components/icons';
import { SubmitButton } from '@components/submit-button';
import { ObjectModel } from '@types';
import { FetchBaseQueryError } from '@services/df/baseApi';
import {
    ClientExtendedRepresentationDetailed,
    PatchedUpdateUserRequest,
    useClientsMePartialUpdateMutation,
} from '@services/df/clients';
import { CreateManualReviewCommentRequest, useMessagesCreateMutation } from '@services/df/messages';
import { checkAuthAction, openAuthPopup } from '@modules/auth';
import { selectUploadJob } from '@modules/upload-models';
import { ModelRow, ModelLabel, ModelThumb, selectUploadedModelsList } from '@modules/models';
import { selectOrderId } from '@modules/order';
import { selectAuthorizedUser, selectUserPermissions, useUserFormLabels } from '@modules/user';
import { useFieldsResponseErrors, useValidation } from '@hooks';
import { getNodeIdComposer, getDirtyValues, objectToFormData, setApiErrors } from '@utils';
import { createAskHelpValidation } from './form-validation';
import { RootPrefix } from '../constants';

import styles from './ask-for-help.module.scss';

type AskForHelpForm_ = Pick<PatchedUpdateUserRequest, 'name' | 'surname' | 'phone_number'> &
    Omit<CreateManualReviewCommentRequest, 'attachments' | 'model_ids'> & {
        attachments?: File[];
        model_ids?: string[]; // useIndeterminateChecked requires string
    };

const FormId = 'ask_for_help_form';
const ToastFieldsErrors: Array<keyof AskForHelpForm_> = ['attachments'];
const UserFields = ['name', 'surname', 'phone_number'] as const;
const MessageFields = ['order', 'upload_job', 'text', 'model_ids', 'attachments'] as const;

// todo get RootPrefix from props
const _id = getNodeIdComposer(RootPrefix, 'ask_for_help');
const formId = getNodeIdComposer(FormId);

const block = bem('rmd-typography');

const AskForHelpTextField = () => {
    const textField = useWatch({ name: 'text' });
    const { t } = useTranslation();

    return (
        <FormField
            prefix={FormId}
            name="text"
            component={TextArea}
            label={t('labels.message', 'Message')}
            errorAsTooltip
            widgetProps={{
                containerProps: {
                    // @ts-ignore
                    'data-replicated-value': textField,
                },
                animate: false,
                resize: 'both',
                rows: 4,
                placeholder: t(
                    'messages.askForHelpCommentPlaceholder',
                    'I’ve seen you offer Nylon (PA12) for other parts, but it’s not showing up as an option for this model. ' +
                        'Could you check if it’s possible to produce this part in Nylon?',
                ),
            }}
        />
    );
};

const AskForHelpAttachmentsField = () => {
    const formApi = useFormContext();

    const handleAttachmentsChange = useCallback(
        files => {
            formApi.setValue('attachments', files.length ? files : undefined);
        },
        [formApi],
    );

    useFieldsResponseErrors<AskForHelpForm_>(formApi, ToastFieldsErrors);

    const { t } = useTranslation();
    const attachFileLabelId = formId('file', 'label');

    return (
        <FileField
            className={styles.file}
            // className={cn('justify-center', styles.file)}
            onChange={handleAttachmentsChange}
            dropzoneProps={{
                noClick: true,
                maxFiles: 999,
                // accept: ['.txt', '.step'],
                minSize: 1,
            }}
            inputProps={{
                id: formId('file', 'field'),
                labelId: attachFileLabelId,
                name: 'attachments',
                theme: 'primary',
                themeType: 'outline',
                icon: false,
                multiple: true,
                children: (
                    <FeatureContent contentKey={attachFileLabelId} fallback={t('buttons.attachFile', 'Attach file')} />
                ),
            }}
        />
    );
};

interface AskForHelpFormProps {
    modelId?: ObjectModel['id'];
    onSubmit?: () => void;
}

const AskForHelpForm = ({ modelId, onSubmit: _onSubmit }: AskForHelpFormProps) => {
    const personalInfo = useAppSelector(selectAuthorizedUser);
    const [updatePersonalInfo] = useClientsMePartialUpdateMutation();
    const [send, { isLoading: isSending }] = useMessagesCreateMutation();

    const uj = useAppSelector(selectUploadJob);
    const orderId = useAppSelector(selectOrderId);
    const uploadedModels = useAppSelector(selectUploadedModelsList);
    const uploadedModelsIds = useMemo(() => uploadedModels.map(model => model.id.toString()), [uploadedModels]);
    const defaultValues = useMemo(
        () => ({
            order: orderId,
            upload_job: uj,
            text: '',
            model_ids: [modelId?.toString()].filter(id => id && uploadedModelsIds.find(_id => _id === id)) as string[],
            ...personalInfo,
        }),
        [modelId, orderId, uj, uploadedModelsIds, personalInfo],
    );

    const [isFirstNameRequired, isLastNameRequired, isPhoneRequired] = useFeatureFlags([
        FF.SignUp.FirstNameRequired,
        FF.SignUp.LastNameRequired,
        FF.SignUp.PhoneRequired,
    ]);
    const validationOptions = useMemo(
        () => ({
            isFirstNameRequired,
            isLastNameRequired,
            isPhoneRequired,
        }),
        [isFirstNameRequired, isLastNameRequired, isPhoneRequired],
    );
    const validationSchema = useValidation(createAskHelpValidation, validationOptions);
    const formApi = useForm<AskForHelpForm_>({
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const { control, handleSubmit, setValue, setError, register, reset } = formApi;

    const { isDirty: isDirtyUser, dirtyFields: userDirtyFields } = useFormState({
        control,
        name: UserFields,
    });

    useEffect(() => {
        register('attachments');
        register('model_ids');
    }, [register]);

    const handleModelsChange = useCallback(
        checkedValues => {
            setValue('model_ids', checkedValues);
        },
        [setValue],
    );

    const onSubmit: SubmitHandler<AskForHelpForm_> = async data => {
        const promises = await Promise.allSettled(
            [
                send({
                    // @ts-ignore useIndeterminateChecked requires string
                    createManualReviewCommentRequest: objectToFormData(pick(data, MessageFields)),
                }).unwrap(),

                isDirtyUser &&
                    updatePersonalInfo({
                        patchedUpdateUserRequest: getDirtyValues(userDirtyFields, pick(data, UserFields)),
                    }).unwrap(),
            ].filter(Boolean),
        );

        const failedQueries = promises.filter(
            (result): result is PromiseRejectedResult => result.status === 'rejected',
        );

        failedQueries.forEach(result => {
            const error = result.reason as FetchBaseQueryError;
            setApiErrors(setError, error.data);
        });

        // sent successfully
        if (!failedQueries.length) {
            reset(defaultValues);
            _onSubmit?.();
        }
    };

    const { getProps, rootProps } = useIndeterminateChecked(uploadedModelsIds, {
        defaultCheckedValues: defaultValues.model_ids,
        onChange: handleModelsChange,
    });

    const { t } = useTranslation();
    const labels = useUserFormLabels(t);

    return (
        <FormProvider {...formApi}>
            <form id={FormId} className="fieldset-grid" onSubmit={handleSubmit(onSubmit)}>
                <div className={cn('fieldset-grid', styles.group)}>
                    <FormField
                        prefix={FormId}
                        name="name"
                        component={TextField}
                        label={labels.first_name}
                        errorAsTooltip
                    />
                    <FormField
                        prefix={FormId}
                        name="surname"
                        component={TextField}
                        label={labels.last_name}
                        errorAsTooltip
                    />
                    <FormField
                        prefix={FormId}
                        name="phone_number"
                        component={TextField}
                        label={labels.phone}
                        errorAsTooltip
                    />
                </div>

                {!!uploadedModels.length && (
                    <div className={cn('fieldset-grid', styles.group)}>
                        <Checkbox
                            id={formId('model_ids', 'field', 'all')}
                            label={t('labels.selectAll', 'Select all')}
                            {...rootProps}
                            disableIconOverlay
                            disableProgrammaticRipple
                            disableRipple
                        />
                        {uploadedModels.map(model => (
                            <ModelRow key={model.id} className={styles.model}>
                                <Checkbox
                                    id={formId('model_ids', 'field', model.id)}
                                    name={formId('model_ids', 'field')}
                                    {...getProps(model.id.toString())}
                                    disableIconOverlay
                                    disableProgrammaticRipple
                                    disableRipple
                                />
                                <ModelThumb thumb={model.thumb_120x120} />
                                <ModelLabel label={model.title} />
                            </ModelRow>
                        ))}
                    </div>
                )}

                <div className={cn('fieldset-grid', styles.group)}>
                    <AskForHelpTextField />
                    <AskForHelpAttachmentsField />
                </div>

                <div className={cn('fieldset-grid-cell', 'flex', 'items-center')}>
                    <SubmitButton prefix={_id()} isSending={isSending}>
                        <FeatureContent contentKey={_id('submit')} fallback={t('buttons.send', 'Send')} />
                    </SubmitButton>
                </div>
            </form>
        </FormProvider>
    );
};

interface AskForHelpProps {
    modelId?: ObjectModel['id'];
    className?: string;
    weight?: TextWeight | null;
    hasIcon?: boolean;
}

export const AskForHelp: React.FC<AskForHelpProps> = ({
    modelId,
    className,
    children,
    weight = 'semi-bold',
    hasIcon = true,
}) => {
    const dispatch = useAppDispatch();
    const [isOpen, open, close] = useToggle(false);

    const handleOpenClick = useCallback(() => {
        dispatch(
            checkAuthAction({
                checker: state => selectUserPermissions(state).isAskForHelpAllowed,
                action: open,
                isClosable: true,
            }),
        );
    }, [dispatch, open]);

    // const elem = useRef(null);
    // useCloseOnOutsideClick({
    //     enabled: visible,
    //     element: elem.current,
    //     onOutsideClick: onClose,
    // });

    const { t } = useTranslation();

    const trigger = (
        <FeatureContent
            contentKey={_id('open')}
            fallback={children || t('buttons.askForHelp', 'Ask for help')}
            wrapper={props => (
                <Button
                    theme="primary"
                    onClick={handleOpenClick}
                    disableRipple
                    className={cn(
                        'ask-for-help-button',
                        'rmd-button--flat',
                        'fade-in',
                        block({ [weight || '']: weight }),
                        className,
                    )}
                    {...props}
                >
                    <TextIconSpacing icon={hasIcon ? <Icons.LifeBuoy /> : null} forceIconWrap>
                        {props.children}
                    </TextIconSpacing>
                </Button>
            )}
        />
    );

    const header = (
        <DialogHeader className={styles.header}>
            <Typography id={_id('title')} type="headline-3" component="div">
                <FeatureContent contentKey={_id('title')} fallback={t('titles.askForHelp', 'Request manual review')} />
            </Typography>
            <Typography type="body-2" className="small-margin-top">
                {t(
                    'messages.askForHelpDesc',
                    'Select the models you need assistance with, briefly describe the issue, and attach any additional documents.',
                )}
            </Typography>
            <Button
                id={_id('close')}
                buttonType="icon"
                theme="clear"
                themeType="outline"
                className={styles.close}
                onClick={close}
                aria-label={t('buttons.close', 'Close')}
            >
                <Icons.Cross />
            </Button>
        </DialogHeader>
    );

    return (
        <>
            {trigger}

            <Dialog
                id={_id('box')}
                visible={isOpen}
                onRequestClose={close}
                aria-labelledby={_id('box')}
                // containerClassName="rmd-dialog-container--scrollable"
                className="relative"
                defaultFocus={`#${formId('text', 'field')}`}
                exit={false}
                // ref={elem}
            >
                {header}

                <DialogContent>
                    <AskForHelpForm key={modelId} modelId={modelId} onSubmit={close} />
                </DialogContent>
            </Dialog>
        </>
    );
};
