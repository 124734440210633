import React from 'react';
import { useAppSelector } from '@app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@react-md/link';
import { ObjectModelStatus } from '@types';
import { AskForHelp } from '@pages/widget/order/ask-for-help';
import { ModelStatusTypes } from '@modules/models';
import { selectAppSettings } from '@modules/app';

export function useModelUploadedStatus(
    status: ObjectModelStatus,
    modelId: number,
    modelLabel: string,
    askForHelpClassName?: string,
): {
    status: ModelStatusTypes;
    label: string | React.ReactElement;
} {
    const { t } = useTranslation();

    switch (status) {
        case ObjectModelStatus.Failed:
            return {
                status: 'error',
                label: (
                    <Trans
                        i18nKey="messages.uploadModelErrorAskForHelp"
                        defaults={"Model couldn't be analyzed, please try to fix it manually or <askForHelp />"}
                        components={{
                            askForHelp: (
                                <AskForHelp
                                    modelId={modelId}
                                    className={askForHelpClassName}
                                    hasIcon={false}
                                    weight="bold"
                                />
                            ),
                        }}
                    />
                ),
            };
        case ObjectModelStatus.Progress:
            return {
                status: 'warning',
                label: t('labels.analyzing', 'Analyzing...'),
            };
        default:
            return {
                status: 'success',
                label: t('labels.ready', 'Ready'),
            };
    }
}

export function useModelRejectedStatus(status: ObjectModelStatus): {
    status: ModelStatusTypes;
    label: string | React.ReactElement;
} {
    const { t } = useTranslation();

    const settings = useAppSelector(selectAppSettings);
    const email = settings.support_email;

    switch (status) {
        case ObjectModelStatus.Failed:
            return {
                status: 'error',
                label: (
                    <Trans
                        i18nKey="messages.uploadModelError"
                        defaults={
                            "Model couldn't be analyzed, please try to fix it manually or send to <lnk>{{ email }}</lnk>"
                        }
                        values={{ email }}
                        components={{
                            lnk: <Link href={`mailto:${email}`} />,
                        }}
                    />
                ),
            };
        case ObjectModelStatus.Progress:
            return {
                status: 'warning',
                label: t('labels.analyzing', 'Analyzing...'),
            };
        default:
            return {
                status: 'success',
                label: t('labels.ready', 'Ready'),
            };
    }
}
